var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { bindMethod } from 'helpers/bind';
import Component from 'navigation/component/Component';
var HomeDiptychProducts = /** @class */ (function (_super) {
    __extends(HomeDiptychProducts, _super);
    function HomeDiptychProducts(el, options) {
        var _this = _super.call(this, el, options) || this;
        Object.defineProperty(_this, "onScroll", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function () {
                var progress = _this.refs.slider.scrollLeft / (_this.refs.slider.scrollWidth - _this.refs.slider.clientWidth);
                _this.refs.track.style.transform = "translateX(".concat(progress * (100) * (_this.refs.items.length - 1), "%)");
            }
        });
        return _this;
    }
    Object.defineProperty(HomeDiptychProducts.prototype, "initialized", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            _super.prototype.initialized.call(this);
        }
    });
    Object.defineProperty(HomeDiptychProducts.prototype, "bindEvents", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (add) {
            var method = bindMethod(add);
            this.refs.slider[method]('scroll', this.onScroll);
        }
    });
    Object.defineProperty(HomeDiptychProducts.prototype, "resize", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            _super.prototype.resize.call(this);
        }
    });
    return HomeDiptychProducts;
}(Component));
export default HomeDiptychProducts;
