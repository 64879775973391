var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import Component from 'navigation/component/Component';
import config from 'core/config';
import sizeStore from 'store/sizeStore';
import { bindMethod } from 'helpers/bind';
import anime from 'animejs';
import { EASE_OUT, frameToMs } from 'helpers/easings';
import store from 'store/store';
import { startPanels } from 'store/middlewares/panels';
var Header = /** @class */ (function (_super) {
    __extends(Header, _super);
    function Header(el, options) {
        var _this = _super.call(this, el, options) || this;
        Object.defineProperty(_this, "motions", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: []
        });
        Object.defineProperty(_this, "onCartUpdate", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (count) {
                _this.refs.headerCart.setAttribute('data-count', count);
                _this.refs.headerCart.classList.toggle('header-right__cart-not-empty', count !== 0);
            }
        });
        Object.defineProperty(_this, "onAlternateLinkUpdate", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (links) {
                if (links === void 0) { links = {}; }
                if (!_this.refs.headerAlternateLinks)
                    return;
                _this.refs.headerAlternateLinks.forEach(function (link) {
                    var lang = link.getAttribute('data-lang');
                    var href = links[lang];
                    if (href)
                        link.setAttribute('href', href);
                    else
                        link.setAttribute('href', config.baseurl + '/' + lang);
                });
            }
        });
        Object.defineProperty(_this, "onLoginStatusUpdate", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (logged) {
                _this.el.classList.toggle('logged', logged);
            }
        });
        Object.defineProperty(_this, "onMenuClicked", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (e) {
                if (store.search.get())
                    store.search.set(null);
                var drawer = store.drawer.get();
                store.drawer.set(drawer === 'menu' ? null : 'menu');
                e.stopImmediatePropagation();
                e.stopPropagation();
                e.preventDefault();
            }
        });
        Object.defineProperty(_this, "onDrawerChange", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (value, previous) {
                var isOpen = value === 'menu';
                if (!isOpen && previous !== 'menu')
                    return;
                _this.cancelMotions();
                if (isOpen)
                    _this.setMenuIconOpened();
                else
                    _this.setMenuIconClosed();
            }
        });
        Object.defineProperty(_this, "onHeaderSearchClicked", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function () { return __awaiter(_this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    store.drawer.set(store.drawer.get() === 'search' ? null : 'search');
                    document.querySelector('.search__inner input').focus();
                    return [2 /*return*/];
                });
            }); }
        });
        Object.defineProperty(_this, "onScroll", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function () {
                sizeStore.headerBottom.set(_this.el.offsetTop + _this.el.offsetHeight);
            }
        });
        _this.resize();
        return _this;
    }
    Object.defineProperty(Header.prototype, "bindEvents", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (add) {
            var _this = this;
            if (add === void 0) { add = true; }
            var method = bindMethod(add);
            store.cart.toggleListener(add, this.onCartUpdate);
            store.logged.toggleListener(add, this.onLoginStatusUpdate);
            store.alternateLinks.toggleListener(add, this.onAlternateLinkUpdate);
            store.drawer.toggleListener(add, function (drawer, previous) { return _this.onDrawerChange(drawer, previous); });
            this.refs.headerSearchButton[method]('click', this.onHeaderSearchClicked);
            this.refs.headerMenuButton[method]('click', this.onMenuClicked);
            this.bindInternalRouter();
        }
    });
    Object.defineProperty(Header.prototype, "show", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            this.el.classList.add('shown');
            startPanels();
        }
    });
    Object.defineProperty(Header.prototype, "bindRefs", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            _super.prototype.bindRefs.call(this);
        }
    });
    Object.defineProperty(Header.prototype, "setMenuIconClosed", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var bar = this.refs.headerMenuButton.querySelector('svg line:first-child');
            var secondBar = this.refs.headerMenuButton.querySelector('svg line:last-child');
            var m, m1, m2, m3;
            var opened = {
                first: {
                    x1: 10,
                    y1: 13,
                    x2: 22,
                    y2: 13
                },
                second: {
                    x1: 10,
                    y1: 19,
                    x2: 22,
                    y2: 19
                }
            };
            m = anime({
                targets: secondBar,
                x1: opened.second.x2,
                y1: opened.second.y2,
                x2: opened.second.x1,
                y2: opened.second.y1,
                easing: EASE_OUT,
                duration: frameToMs(25),
                delay: frameToMs(10)
            });
            m1 = anime({
                targets: bar,
                x1: opened.first.x1,
                y1: opened.first.y1,
                x2: opened.first.x2,
                y2: opened.first.y2,
                easing: EASE_OUT,
                duration: frameToMs(25),
                delay: frameToMs(10)
            });
            m2 = anime({
                targets: this.refs.headerMenuOpenText,
                opacity: 1,
                translateX: 0,
                easing: EASE_OUT,
                duration: frameToMs(25),
                delay: frameToMs(10)
                // delay: isOpen ? 0 : frameToMs(50 / 2)
            });
            m3 = anime({
                targets: this.refs.headerMenuCloseText,
                opacity: 0,
                translateX: '-30%',
                easing: EASE_OUT,
                duration: frameToMs(25),
                delay: frameToMs(10)
            });
            this.motions.push(m, m1, m2, m3);
        }
    });
    Object.defineProperty(Header.prototype, "setMenuIconOpened", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var bar = this.refs.headerMenuButton.querySelector('svg line:first-child');
            var secondBar = this.refs.headerMenuButton.querySelector('svg line:last-child');
            var m, m1, m2, m3;
            var closed = {
                first: {
                    x1: 12.1,
                    y1: 11.9,
                    x2: 20.6,
                    y2: 21.1
                },
                second: {
                    x1: 20.6,
                    y1: 11.9,
                    x2: 12.1,
                    y2: 21.1
                }
            };
            m = anime({
                targets: secondBar,
                x1: closed.second.x1,
                y1: closed.second.y1,
                x2: closed.second.x2,
                y2: closed.second.y2,
                easing: EASE_OUT,
                duration: frameToMs(25)
            });
            m1 = anime({
                targets: bar,
                x1: closed.first.x1,
                y1: closed.first.y1,
                x2: closed.first.x2,
                y2: closed.first.y2,
                easing: EASE_OUT,
                duration: frameToMs(25)
            });
            m2 = anime({
                targets: this.refs.headerMenuOpenText,
                opacity: 0,
                translateX: '30%',
                easing: EASE_OUT,
                duration: frameToMs(25)
                // delay: isOpen ? 0 : frameToMs(50 / 2)
            });
            m3 = anime({
                targets: this.refs.headerMenuCloseText,
                opacity: 1,
                translateX: 0,
                easing: EASE_OUT,
                duration: frameToMs(25)
            });
            this.motions.push(m, m1, m2, m3);
        }
    });
    Object.defineProperty(Header.prototype, "resize", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var height = this.el.offsetHeight;
            sizeStore.headerHeight.set(height);
        }
    });
    Object.defineProperty(Header.prototype, "cancelMotions", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            this.motions.forEach(function (m) { return m.pause(); });
            this.motions = [];
        }
    });
    return Header;
}(Component));
export default Header;
