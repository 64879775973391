var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
/* global AdyenConfiguration */
import { defer, pick, pickBy, each } from 'lodash';
import { compact } from 'lodash-es';
import { AdyenCheckout, ApplePay, Bancontact, Card, Dropin, GooglePay, PayPal, Klarna } from '@adyen/adyen-web';
import Form from 'components/form/Form';
import { adyenConfig, adyenPaymentMethodConfig, updateConfigs } from 'core/adyenConfig';
import { bindEmitterMethod, bindMethod } from 'helpers/bind';
import anime from 'animejs';
import { EASE_IN_OUT } from 'helpers/easings';
import CheckoutPage from 'navigation/pages/CheckoutPage';
import RadioGroup from 'components/radio-group/RadioGroup';
var CheckoutPayment = /** @class */ (function (_super) {
    __extends(CheckoutPayment, _super);
    function CheckoutPayment() {
        var _this = _super.apply(this, __spreadArray([], __read(arguments), false)) || this;
        Object.defineProperty(_this, "validations", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(_this, "dropin", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(_this, "triggerSubmit", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function () { return __awaiter(_this, void 0, void 0, function () {
                var cssVariables, span;
                var _this = this;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            if (!this.dropin.isValid) {
                                this.dropin.showValidation();
                                return [2 /*return*/];
                            }
                            cssVariables = {
                                loadingProgress: 0
                            };
                            this.refs.payNow.classList.add('loading');
                            span = this.refs.payNow.querySelector('.button__text-inner').children[0];
                            span.innerText = this.refs.payNow.dataset.loading;
                            return [4 /*yield*/, anime({
                                    targets: cssVariables,
                                    loadingProgress: [0, 1],
                                    duration: 2000,
                                    easing: EASE_IN_OUT,
                                    begin: function () {
                                        _this.refs.payNow.setAttribute('disabled', 'true');
                                    },
                                    update: function () {
                                        _this.refs.payNow.style.setProperty('--loading-progress', "".concat(cssVariables.loadingProgress));
                                    },
                                    complete: function () {
                                        _this.refs.payNow.removeAttribute('disabled');
                                        _this.dropin.submit();
                                        _this.refs.payNow.classList.add('paynow-hidden');
                                    }
                                }).finished];
                        case 1:
                            _a.sent();
                            return [2 /*return*/];
                    }
                });
            }); }
        });
        Object.defineProperty(_this, "onTestCard", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (event) {
                event.preventDefault();
                var date = new Date();
                var data = {
                    CT: event.currentTarget.getAttribute('data-test-cc'),
                    CARDNO: event.currentTarget.getAttribute('data-test-number'),
                    ECOM_CARDINFO_EXPDATE_YEAR: date.getFullYear(),
                    ECOM_CARDINFO_EXPDATE_MONTH: date.getMonth() + 1,
                    CVC: '123',
                    CN: 'Test'
                };
                var form = _this.modules.form;
                each(data, function (val, key) {
                    var input = form.el.elements[key];
                    switch (input.type) {
                        case 'checkbox':
                            input.checked = !!val;
                            break;
                        default:
                            input.value = val;
                            break;
                    }
                });
                setTimeout(function () { return form.quickValid(); }, 10);
            }
        });
        return _this;
    }
    Object.defineProperty(CheckoutPayment.prototype, "getModulesMap", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var modules = _super.prototype.getModulesMap.call(this);
            delete modules.radioGroup;
            return __assign(__assign({}, modules), { creditForm: ['.credit-form', Form], radioGroup: ['.form .radio-group', RadioGroup], creditRadioGroup: ['.credit-form .radio-group', RadioGroup] });
        }
    });
    Object.defineProperty(CheckoutPayment.prototype, "askPreload", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (previousPage) {
            var script = this.el.querySelector('.config-adyen');
            if (script) {
                var parent_1 = script.parentNode;
                parent_1.removeChild(script);
                var s = document.createElement('script');
                s.innerHTML = script.innerHTML;
                parent_1.appendChild(s);
                updateConfigs();
            }
            return _super.prototype.askPreload.call(this, previousPage);
        }
    });
    Object.defineProperty(CheckoutPayment.prototype, "show", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (previousPage) {
            return __awaiter(this, void 0, void 0, function () {
                var checkout;
                var _this = this;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            if (!adyenConfig) return [3 /*break*/, 2];
                            return [4 /*yield*/, AdyenCheckout(adyenConfig)];
                        case 1:
                            checkout = _a.sent();
                            this.dropin = new Dropin(checkout, {
                                paymentMethodComponents: [Card, PayPal, GooglePay, ApplePay, Bancontact, Klarna],
                                paymentMethodsConfiguration: __assign({}, adyenPaymentMethodConfig),
                                onReady: function () {
                                    _this.refs.payNow.addEventListener('click', function () { return _this.triggerSubmit(); });
                                    _this.onShowComplete();
                                },
                                onSelect: function (activeComponent) {
                                    var showPayButton = activeComponent.type !== 'scheme';
                                    _this.refs.payNow.classList.toggle('paynow-hidden', showPayButton);
                                    setTimeout(function () {
                                        var checkbox = _this.refs.adyen.querySelector('.adyen-checkout__checkbox__input');
                                        var radioBox = _this.refs.adyen.querySelector('.radio__box');
                                        if (_this.refs.adyen.contains(radioBox) || !checkbox)
                                            return;
                                        var fakeCheckbox = document.createElement('div');
                                        fakeCheckbox.classList.add('radio__box');
                                        checkbox.after(fakeCheckbox);
                                    }, 20);
                                }
                            }).mount(this.refs.adyen);
                            _a.label = 2;
                        case 2: return [2 /*return*/, _super.prototype.show.call(this, previousPage)];
                    }
                });
            });
        }
    });
    Object.defineProperty(CheckoutPayment.prototype, "bindEvents", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (add) {
            var _this = this;
            if (add === void 0) { add = true; }
            _super.prototype.bindEvents.call(this, add);
            var method = bindEmitterMethod(add);
            var method2 = bindMethod(add);
            if (!Array.isArray(this.modules.radioGroup))
                this.modules.radioGroup = compact([this.modules.radioGroup]);
            this.modules.radioGroup.forEach(function (r) { return r[method]('change', _this.onMethodChange, _this); });
            if (this.modules.creditRadioGroup)
                this.modules.creditRadioGroup[method]('change', this.onCreditChange, this);
            // this.onMethodChange()
            Array.from(this.el.querySelectorAll('[data-test-cc]')).forEach(function (b) { return b[method2]('click', _this.onTestCard); });
        }
    });
    Object.defineProperty(CheckoutPayment.prototype, "bindModules", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            _super.prototype.bindModules.call(this);
            var form = this.modules.form;
            if (!form)
                return;
            var validations = Object.assign({}, form.validations);
            var baseValidation = pick(validations, ['mode']);
            var cardValidations = pickBy(validations, function (value, key) { return key.match(/^(CT|CARDNO|ECOM_CARDINFO_EXPDATE_.+|CVC|CN)$/); });
            this.validations = {
                default: validations,
                card: cardValidations,
                base: baseValidation
            };
            form.preventDefault = false;
        }
    });
    Object.defineProperty(CheckoutPayment.prototype, "onCreditChange", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (value, name) {
            this.modules.creditForm.submit();
        }
    });
    Object.defineProperty(CheckoutPayment.prototype, "onMethodChange", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (value, name, input) {
            var _this = this;
            if (!this.validations)
                return;
            defer(function () {
                var data = _this.modules.radioGroup.reduce(function (memo, radio) {
                    memo[radio.name] = radio.value;
                    return memo;
                }, {});
                var schema = __assign({}, _this.validations.base);
                var form = _this.modules.form;
                if (input && name === 'mode') {
                    if (input.hasAttribute('data-action')) {
                        form.action = input.getAttribute('data-action');
                        form.el.action = form.action;
                    }
                }
                if (data.mode === 'card')
                    Object.assign(schema, _this.validations.card);
                form.updateSchema(schema);
                setTimeout(function () { return form.quickValid(); }, 10);
            });
        }
    });
    Object.defineProperty(CheckoutPayment.prototype, "resize", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            _super.prototype.resize.call(this);
        }
    });
    Object.defineProperty(CheckoutPayment.prototype, "flush", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            _super.prototype.flush.call(this);
        }
    });
    return CheckoutPayment;
}(CheckoutPage));
export default CheckoutPayment;
