var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import anime from 'animejs';
import { bindMethod } from 'helpers/bind';
import detect from 'helpers/detect';
import { EASE_OUT, frameToMs } from 'helpers/easings';
import { clamp } from 'lodash-es';
import Component, { forceArray } from 'navigation/component/Component';
import mqStore from 'store/mqStore';
var DragController = /** @class */ (function (_super) {
    __extends(DragController, _super);
    function DragController(el, options) {
        var _this = _super.call(this, el, options) || this;
        Object.defineProperty(_this, "x", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: 0
        });
        Object.defineProperty(_this, "targetX", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: 0
        });
        Object.defineProperty(_this, "delta", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: 0
        });
        Object.defineProperty(_this, "_progress", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: 0
        });
        Object.defineProperty(_this, "itemsVisible", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: 0
        });
        Object.defineProperty(_this, "currentItem", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: 0
        });
        Object.defineProperty(_this, "limit", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: 0
        });
        Object.defineProperty(_this, "start", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: 0
        });
        Object.defineProperty(_this, "grabbingTimeout", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(_this, "snappingTimeout", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(_this, "snaps", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(_this, "holding", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(_this, "mouse", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: {
                x: 0,
                prevX: 0,
                startTime: null
            }
        });
        Object.defineProperty(_this, "_grabbing", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(_this, "_moving", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(_this, "_native", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(_this, "gradient", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(_this, "initialized", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function () {
                _super.prototype.initialized.call(_this);
                _this.onTabletPortrait(mqStore.tabletPortrait.get());
                var current = forceArray(_this.refs.draggableItems).find(function (item) { return item === null || item === void 0 ? void 0 : item.classList.contains('current'); });
                _this.resize();
                if (current)
                    _this.move(current.offsetLeft - 40);
                var mobile = mqStore.tabletPortrait.get();
                if (_this.start && _this.start > 0) {
                    var item = forceArray(_this.refs.draggableItems)[mobile ? _this.start : _this.start - 1];
                    if (item) {
                        if (!mobile)
                            _this.move(item.offsetLeft - 40, true);
                        else
                            _this.el.children[0].scrollLeft = item.offsetLeft;
                    }
                }
            }
        });
        Object.defineProperty(_this, "onScroll", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (e) {
                e.preventDefault();
                e.stopPropagation();
                e.stopImmediatePropagation();
                _this.progress = clamp(_this.el.children[0].scrollLeft / (_this.el.children[0].scrollWidth - _this.el.children[0].clientWidth), 0, 1);
                _this.emit('move');
            }
        });
        Object.defineProperty(_this, "onWheel", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (e) {
                if (_this.native)
                    return;
                var absX = Math.abs(e.deltaX);
                var absY = Math.abs(e.deltaY);
                if (absX > absY) {
                    e.preventDefault();
                    e.stopPropagation();
                    e.stopImmediatePropagation();
                    _this.move(e.deltaX);
                }
            }
        });
        Object.defineProperty(_this, "onMouseDown", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (e) {
                if (_this.native)
                    return;
                _this.mouse = {
                    x: e.clientX,
                    prevX: e.clientX,
                    startTime: Date.now()
                };
                _this.holding = true;
            }
        });
        Object.defineProperty(_this, "onMouseMove", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (e) {
                if (_this.native)
                    return;
                if (_this.holding)
                    _this.grabbing = true;
                if (_this.grabbing) {
                    if (!_this.moving)
                        _this.moving = true;
                    var delta = e.clientX - _this.mouse.x;
                    _this.move(-delta);
                    _this.mouse.prevX = _this.mouse.x;
                    _this.mouse.x = e.clientX;
                }
            }
        });
        Object.defineProperty(_this, "onMouseUp", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (e) {
                clearTimeout(_this.grabbingTimeout);
                if (_this.native)
                    return;
                _this.holding = false;
                if (_this.mouse.startTime) {
                    if (_this.mouse.startTime + 100 < Date.now()) {
                        e.preventDefault();
                        _this.grabbing = false;
                        _this.moving = false;
                    }
                }
            }
        });
        Object.defineProperty(_this, "onTabletPortrait", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (tabletPortrait) {
                if (tabletPortrait && detect.touch)
                    _this.native = true;
                else
                    _this.native = false;
            }
        });
        Object.defineProperty(_this, "move", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (delta, force) {
                if (force === void 0) { force = false; }
                if (_this.limit === 0)
                    return;
                if (_this.snaps)
                    clearTimeout(_this.snappingTimeout);
                _this.delta = clamp(_this.delta - delta, -_this.limit, 0);
                forceArray(_this.refs.draggableItems).forEach(function (item) {
                    if (item)
                        item.style.transform = "translateX(".concat(_this.delta, "px)");
                });
                _this.progress = Math.abs(_this.delta / -_this.limit);
                if (_this.itemsVisible > 1)
                    _this.currentItem = clamp(_this.itemsVisible + Math.round(_this.progress * (_this.refs.draggableItems.length)), _this.itemsVisible, _this.refs.draggableItems.length);
                else
                    _this.currentItem = clamp(_this.itemsVisible - 1 + Math.round(_this.progress * (_this.refs.draggableItems.length - 1)), _this.itemsVisible - 1, _this.refs.draggableItems.length - 1);
                if (_this.snaps) {
                    _this.snappingTimeout = setTimeout(function () {
                        _this.snapping(force);
                    }, 200);
                }
                _this.emit('move');
            }
        });
        Object.defineProperty(_this, "navigate", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (direction) {
                var target = clamp(_this.currentItem + (_this.itemsVisible) * direction, 0, _this.refs.draggableItems.length);
                if (target < 0 || target > _this.refs.draggableItems.length)
                    return;
                if (_this.native)
                    return;
                var targetProgress = Math.max((target - _this.itemsVisible), 0) / (_this.refs.draggableItems.length - _this.itemsVisible);
                _this.delta = -_this.limit * targetProgress;
                anime({
                    targets: _this.refs.draggableItems,
                    translateX: _this.delta,
                    duration: frameToMs(50),
                    easing: EASE_OUT
                });
                _this.currentItem = target;
                _this.progress = targetProgress;
                _this.emit('move');
            }
        });
        Object.defineProperty(_this, "resize", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function () {
                _super.prototype.resize.call(_this);
                _this.limit = _this.el.children[0].scrollWidth - _this.el.children[0].clientWidth;
                _this.delta = clamp(_this.delta, -_this.limit, 0);
                forceArray(_this.refs.draggableItems).forEach(function (item) {
                    if (item)
                        item.style.transform = "translateX(".concat(_this.delta, "px)");
                });
            }
        });
        _this.itemsVisible = options.itemsVisible || 1;
        _this.currentItem = _this.itemsVisible;
        _this.gradient = options.gradient || false;
        _this.snaps = options.snaps || false;
        _this.start = options.start || 0;
        if (_this.gradient) {
            _this.el.classList.add('has-gradient');
            _this.el.classList.add('right');
        }
        return _this;
    }
    Object.defineProperty(DragController.prototype, "progress", {
        get: function () {
            return this._progress;
        },
        set: function (value) {
            if (value === 0)
                this.el.classList.remove('left');
            else
                this.el.classList.add('left');
            if (value === 1)
                this.el.classList.remove('right');
            else
                this.el.classList.add('right');
            this._progress = value;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DragController.prototype, "grabbing", {
        get: function () {
            return this._grabbing;
        },
        set: function (value) {
            this._grabbing = value;
            if (value)
                this.el.classList.add('grabbing');
            else
                this.el.classList.remove('grabbing');
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DragController.prototype, "moving", {
        get: function () {
            return this._moving;
        },
        set: function (value) {
            this._moving = value;
            if (value) {
                this.el.classList.add('moving');
                document.documentElement.classList.add('no-select');
            }
            else {
                this.el.classList.remove('moving');
                document.documentElement.classList.remove('no-select');
                this._grabbing = false;
                this._moving = false;
            }
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DragController.prototype, "native", {
        get: function () {
            return this._native;
        },
        set: function (value) {
            if (value) {
                this.delta = 0;
                forceArray(this.refs.draggableItems).forEach(function (item) {
                    item.style.transform = '';
                });
                this.el.classList.add('native');
                this._native = true;
            }
            else {
                this.el.classList.remove('native');
                this._native = false;
            }
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DragController.prototype, "bindEvents", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (add) {
            var _this = this;
            var method = bindMethod(add);
            this.el.children[0][method]('wheel', function (e) { return _this.onWheel(e); });
            this.el.children[0][method]('scroll', function (e) { return _this.onScroll(e); });
            this.el[method]('mousedown', function (e) { return _this.onMouseDown(e); });
            window[method]('mousemove', function (e) { return _this.onMouseMove(e); });
            window[method]('mouseup', function (e) { return _this.onMouseUp(e); });
            mqStore.tabletPortrait.toggleListener(add, this.onTabletPortrait);
        }
    });
    Object.defineProperty(DragController.prototype, "snapping", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (force) {
            if (force === void 0) { force = false; }
            this.progress = this.currentItem / (this.refs.draggableItems.length - 1);
            this.delta = -this.limit * this.progress;
            anime({
                targets: this.refs.draggableItems,
                translateX: this.delta,
                duration: force ? 0 : frameToMs(25),
                easing: EASE_OUT
            });
        }
    });
    return DragController;
}(Component));
export default DragController;
