var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import router, { getRoot } from 'core/router';
import { bindMethod } from 'helpers/bind';
import Component from 'navigation/component/Component';
import store from 'store/store';
var ReturnsBreadcrumbs = /** @class */ (function (_super) {
    __extends(ReturnsBreadcrumbs, _super);
    function ReturnsBreadcrumbs() {
        var _this = _super.apply(this, __spreadArray([], __read(arguments), false)) || this;
        Object.defineProperty(_this, "onStepUpdate", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (stepNumber) {
                _this.refs.breadcrumbSteps.forEach(function (step, i) {
                    step.classList.toggle('current', (i + 1) === stepNumber);
                    step.classList.toggle('disabled', (i + 1) > stepNumber);
                    if ((i + 1) === stepNumber) {
                        _this.el.scrollTo({
                            left: step.offsetLeft - _this.el.offsetWidth / 2 + step.offsetWidth / 2,
                            behavior: 'smooth'
                        });
                    }
                });
            }
        });
        Object.defineProperty(_this, "onStepClick", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (event) {
                var target = event === null || event === void 0 ? void 0 : event.currentTarget;
                var url = target === null || target === void 0 ? void 0 : target.dataset.url;
                if (target === null || target === void 0 ? void 0 : target.classList.contains('disabled'))
                    return;
                if (url)
                    router.navigate(url === null || url === void 0 ? void 0 : url.replace(getRoot(), ''));
            }
        });
        return _this;
    }
    Object.defineProperty(ReturnsBreadcrumbs.prototype, "bindEvents", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (add) {
            var _this = this;
            if (add === void 0) { add = true; }
            var emitterMethod = add ? 'listenAndStart' : 'unlisten';
            var method = bindMethod(add);
            store.returnsStep[emitterMethod](this.onStepUpdate);
            this.refs.breadcrumbSteps.forEach(function (step, i) {
                step[method]('click', _this.onStepClick);
            });
        }
    });
    return ReturnsBreadcrumbs;
}(Component));
export default ReturnsBreadcrumbs;
