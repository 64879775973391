var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import CheckoutPage from 'navigation/pages/CheckoutPage';
import Form from 'components/form/Form';
var ReturnsSummary = /** @class */ (function (_super) {
    __extends(ReturnsSummary, _super);
    function ReturnsSummary() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(ReturnsSummary.prototype, "initialized", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            _super.prototype.initialized.call(this);
        }
    });
    Object.defineProperty(ReturnsSummary.prototype, "getModulesMap", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return {
                form: ['.returns__items-form', Form],
            };
        }
    });
    return ReturnsSummary;
}(CheckoutPage));
export default ReturnsSummary;
