import Category from 'sections/category/Category';
import Cart from 'sections/checkout/Cart';
import Customer from 'sections/customer/Customer';
import Profile from 'sections/customer/Profile';
import Product from 'sections/product/Product';
import Faq from 'sections/faq/Faq';
import Auth from 'sections/customer/Auth';
import Wishlist from 'sections/customer/Wishlist';
import SearchResults from 'sections/search/SearchResults';
import Collection from 'sections/collections/Collection';
import Quickbuy from 'components/quick-buy/Quickbuy';
import SizeGuide from 'sections/size-guide/SizeGuide';
import Checkout from 'sections/checkout/Checkout';
import CheckoutAddress from 'sections/checkout/CheckoutAddress';
import Home from 'sections/home/Home';
import Advices from 'sections/advices/Advices';
import Article from 'sections/journal/Article';
import Journal from 'sections/journal/Journal';
import OrderTracking from 'sections/order-tracking/OrderTracking';
import CheckoutSummary from 'sections/checkout/partials/CheckoutSummary';
import Legals from 'sections/legals/Legals';
import Brand from 'sections/brand/Brand';
import CheckoutPayment from 'sections/checkout/CheckoutPayment';
import Contact from 'sections/contact/Contact';
import Orders from 'sections/customer/Orders';
import ContactPanel from 'sections/contact/ContactPanel';
import Alert from 'sections/product/partials/Alert';
import EditAddress from 'sections/address/EditAddress';
import Alerts from 'sections/customer/Alerts';
import Collections from 'sections/collections/Collections';
import Returns from 'sections/returns/Returns';
import ReturnsItems from 'sections/returns/ReturnsItems';
import ReturnsItemSelectSize from 'sections/returns/partials/ReturnsItemSelectSize';
import ReturnsSelectColor from 'sections/returns/partials/ReturnsSelectColor';
import CheckoutPersonalDetails from 'sections/checkout/CheckoutPersonalDetails';
import Stores from 'sections/stores/Stores';
import CheckoutShipping from 'sections/checkout/CheckoutShipping';
import CheckoutPickup from 'sections/checkout/partials/CheckoutPickup';
import Store from 'sections/stores/Store';
import ReturnsSummary from 'sections/returns/ReturnsSummary';
var pageMap = {
    /* Main */
    category: Category,
    checkout: Checkout,
    product: Product,
    collection: Collection,
    collections: Collections,
    stores: Stores,
    store: Store,
    home: Home,
    article: Article,
    journal: Journal,
    brand: Brand,
    faq: Faq,
    auth: Auth,
    wishlist: Wishlist,
    sizeGuide: SizeGuide,
    alert: Alert,
    alerts: Alerts,
    orderTracking: OrderTracking,
    /* Panel */
    cart: Cart,
    /* Search */
    results: SearchResults,
    /* Checkout */
    checkoutPersonalDetails: CheckoutPersonalDetails,
    checkoutShipping: CheckoutShipping,
    checkoutSummary: CheckoutSummary,
    checkoutPayment: CheckoutPayment,
    checkoutPickup: CheckoutPickup,
    checkoutAddress: CheckoutAddress,
    editAddress: EditAddress,
    quickBuy: Quickbuy,
    /* Customer */
    customer: Customer,
    profile: Profile,
    orders: Orders,
    returns: Returns,
    returnsItems: ReturnsItems,
    returnsSummary: ReturnsSummary,
    returnsItemSelectSize: ReturnsItemSelectSize,
    returnsSelectColor: ReturnsSelectColor,
    /* Legals */
    legals: Legals,
    advices: Advices,
    contact: Contact,
    contactPanel: ContactPanel
};
export default pageMap;
