var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { defer } from 'lodash-es';
import PageManager from 'navigation/page-manager/MainPageManager';
import MainPage from 'navigation/pages/MainPage';
import Component from 'navigation/component/Component';
import router from 'core/router';
import mqStore from 'store/mqStore';
import resize from 'helpers/resize';
import store from 'store';
import Panel from 'components/panel/Panel';
import Header from 'components/header/Header';
import scroll from 'core/scroll';
import Toaster from 'components/toaster/Toaster';
import GridHelper from 'components/grid-helper/GridHelper';
import Menu from 'components/menu/Menu';
import Overlay from 'components/overlay/Overlay';
import WebstoreSwitcher from 'components/webstore-switcher/WebstoreSwitcher';
import anime from 'animejs';
import { bindEmitterMethod } from 'helpers/bind';
import Notification from 'components/notification/Notification';
import sizeStore from 'store/sizeStore';
import Search from 'sections/search/Search';
import LoadingBar from 'components/loading-bar/LoadingBar';
import BlueshiftPopin from 'components/blueshift-popin/BlueshiftPopin';
import Popin from 'components/popin/Popin';
var App = /** @class */ (function (_super) {
    __extends(App, _super);
    function App(el) {
        var _this = _super.call(this, el) || this;
        Object.defineProperty(_this, "pageTop", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: 0
        });
        Object.defineProperty(_this, "loadingTl", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: anime.timeline()
        });
        Object.defineProperty(_this, "headerVariants", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: {
                solid: { className: 'header__solid' },
                transparent: { className: 'header__transparent' },
                dark: { className: 'header__dark' },
                light: { className: 'header__light' },
                'solid-fixed': { className: 'header__solid-fixed' },
                'only-logo': { className: 'header__only-logo' },
                'only-logo-returns': { className: 'header__only-logo-returns' }
            }
        });
        Object.defineProperty(_this, "show", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function () {
                _this.invoke('show');
            }
        });
        Object.defineProperty(_this, "onLoading", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (loading) {
                document.documentElement.classList.toggle('loading', loading);
            }
        });
        Object.defineProperty(_this, "resize", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function () {
                var _a = resize.dimensions(), height = _a.height, width = _a.width;
                if (!CSS.supports('width', '100dvh'))
                    document.documentElement.style.setProperty('--vh', height + 'px');
                document.documentElement.style.setProperty('--vw', width + 'px');
                defer(function () {
                    _super.prototype.resize.call(_this);
                    _this.invoke('resize');
                    _this.updateFixedTop();
                });
            }
        });
        Object.defineProperty(_this, "updateFixedTop", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function () {
                var fixedTop = Math.max(0, sizeStore.notificationHeight.get() - document.scrollingElement.scrollTop);
                var pageTop = fixedTop + sizeStore.headerHeight.get();
                if (pageTop === _this.pageTop)
                    return;
                _this.pageTop = pageTop;
                document.documentElement.style.setProperty('--notification-height', sizeStore.notificationHeight.get() + 'px');
                document.documentElement.style.setProperty('--header-top', fixedTop + 'px');
                document.documentElement.style.setProperty('--page-top', (fixedTop + sizeStore.headerHeight.get()) + 'px');
            }
        });
        scroll.init();
        resize.setRoot(_this.refs.container);
        resize.add(_this);
        router.resolve();
        return _this;
    }
    Object.defineProperty(App.prototype, "initialized", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            _super.prototype.initialized.call(this);
            this.setInitialHeaderVariants();
        }
    });
    Object.defineProperty(App.prototype, "getModulesMap", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return {
                panel: ['.panel', Panel],
                menu: ['.menu', Menu],
                blueshiftPopin: ['.blueshift-popin', BlueshiftPopin],
                search: ['.search', Search],
                popin: ['.popin', Popin],
                notification: ['.notification', Notification],
                header: ['.header', Header],
                toaster: ['.toaster', Toaster],
                gridHelper: ['.grid-helper', GridHelper],
                overlay: ['.overlay', Overlay],
                webstoreSwitcher: ['.webstore-switcher', WebstoreSwitcher],
                loadingBar: ['.loading-bar', LoadingBar]
            };
        }
    });
    Object.defineProperty(App.prototype, "bindModules", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            this.pageManager = new PageManager(this.refs.container, '.page', MainPage, {}, {
                crossTransition: false
            });
            _super.prototype.bindModules.call(this);
            store.routers.get().main = this.pageManager;
            this.modules.pageManager = this.pageManager;
        }
    });
    Object.defineProperty(App.prototype, "bindEvents", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (add) {
            var _this = this;
            resize.add(this);
            scroll.on(this.updateFixedTop);
            var emitterMethod = bindEmitterMethod(add);
            this.pageManager.once('show', this.show);
            this.pageManager[emitterMethod]('load:start', this.modules.loadingBar.begin);
            this.pageManager[emitterMethod]('load:end', this.modules.loadingBar.complete);
            store.headerVariants.toggleListener(add, function (variants) { return _this.updateHeaderVariants(variants); });
            store.panel.toggleListener(add, function (panel) { return _this.onPanelChange(panel); });
            this.resize();
            this.updateFixedTop();
        }
    });
    Object.defineProperty(App.prototype, "onPanelChange", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (panel) {
            if (panel)
                this.modules.toaster.hideAllMessages();
        }
    });
    Object.defineProperty(App.prototype, "setInitialHeaderVariants", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var classes = Array.from(document.body.classList);
            var variants = classes.filter(function (c) { return c.includes('header__'); }).map(function (c) { return c.replace('header__', ''); });
            store.headerVariants.set(variants);
        }
    });
    Object.defineProperty(App.prototype, "updateHeaderVariants", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (variants) {
            var _this = this;
            Object.keys(this.headerVariants).forEach(function (key) {
                var variant = _this.headerVariants[key];
                document.body.classList.toggle(variant.className, variants.includes(key));
            });
        }
    });
    Object.defineProperty(App.prototype, "updateSizes", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var _a = resize.dimensions(), innerHeight = _a.innerHeight, height = _a.height;
            if (mqStore && mqStore.tabletPortrait) {
                document.documentElement.style.setProperty('--ivh', innerHeight + 'px');
                if (this.refs) {
                    var offsetTop = (this.refs ? this.refs.container : { offsetTop: 0 }).offsetTop;
                    document.documentElement.style.setProperty('--available-height', (height - offsetTop) + 'px');
                    document.documentElement.style.setProperty('--available-inner-height', (innerHeight - offsetTop) + 'px');
                    this.updateFixedTop();
                }
            }
        }
    });
    return App;
}(Component));
export default App;
