var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import ProductCard from 'sections/product/partials/ProductCard';
import { bindMethod } from 'helpers/bind';
import Component from 'navigation/component/Component';
import modulesMap from 'core/modulesMap';
var BlockProducts = /** @class */ (function (_super) {
    __extends(BlockProducts, _super);
    function BlockProducts() {
        var _this = _super.apply(this, __spreadArray([], __read(arguments), false)) || this;
        Object.defineProperty(_this, "currentItem", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: 0
        });
        Object.defineProperty(_this, "fraction", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: 0
        });
        Object.defineProperty(_this, "onScroll", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function () {
                _this.currentItem = Math.round(_this.refs.articleProductsSlider.scrollLeft / _this.fraction);
                _this.setButtonDisabledState(_this.currentItem);
                var progress = _this.refs.articleProductsSlider.scrollLeft / (_this.refs.articleProductsSlider.scrollWidth - _this.refs.articleProductsSlider.clientWidth);
                _this.refs.articleProductsTrack.style.transform = "translateX(".concat(progress * (100) * (_this.refs.items.length - 1), "%)");
            }
        });
        return _this;
    }
    Object.defineProperty(BlockProducts.prototype, "initialized", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            _super.prototype.initialized.call(this);
            this.fraction = this.refs.articleProductsSlider.scrollWidth / this.refs.items.length;
        }
    });
    Object.defineProperty(BlockProducts.prototype, "getModulesMap", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return __assign(__assign({}, modulesMap), { productCard: ['.product-card', ProductCard] });
        }
    });
    Object.defineProperty(BlockProducts.prototype, "getModuleParams", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (el, componentConstructor) {
            {
                return {
                    parent: this,
                    pageManager: this.options.pageManager
                };
            }
        }
    });
    Object.defineProperty(BlockProducts.prototype, "bindEvents", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (add) {
            var _this = this;
            var method = bindMethod(add);
            this.refs.articleProductsSlider[method]('scroll', this.onScroll);
            if (this.refs.prev && this.refs.next) {
                this.refs.prev[method]('click', function (e) { return _this.navigateItem(e, -1); });
                this.refs.next[method]('click', function (e) { return _this.navigateItem(e, 1); });
            }
        }
    });
    Object.defineProperty(BlockProducts.prototype, "navigateItem", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (e, direction) {
            e.preventDefault();
            if (this.currentItem + direction < 0 || this.currentItem + direction >= this.refs.items.length - 2)
                return;
            this.setButtonDisabledState(this.currentItem);
            this.currentItem += direction;
            this.refs.articleProductsSlider.scrollTo({
                left: this.currentItem * this.fraction,
                behavior: 'smooth'
            });
        }
    });
    Object.defineProperty(BlockProducts.prototype, "setButtonDisabledState", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (current) {
            if (this.refs.prev)
                this.refs.prev.classList.toggle('disabled', current === 0);
            if (this.refs.next)
                this.refs.next.classList.toggle('disabled', current === this.refs.items.length - 3);
        }
    });
    return BlockProducts;
}(Component));
export default BlockProducts;
