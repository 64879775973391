var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { updatePageLinks } from 'core/router';
import { extractInfoFromXhr } from 'navigation/page-manager/CustomPageManager';
import InternalPage from 'navigation/pages/InternalPage';
import browser from 'helpers/browser';
import store from 'store';
import ProductCard from 'sections/product/partials/ProductCard';
import CatalogPush from 'components/catalog-push/CatalogPush';
import { bindEmitterMethod } from 'helpers/bind';
import { forceArray } from 'navigation/component/Component';
import Pagination from 'components/pagination/Pagination';
import RevealList from 'components/reveal-list/RevealList';
import scroll from 'core/scroll';
import { frameToMs } from 'helpers/easings';
var MAX_LAST_STATES = 10;
export var findMostPopularNumber = function (arr) {
    // Initialize an object to hold counts of each number
    var counts = {};
    // Iterate over the array to count occurrences of each number
    arr.forEach(function (num) {
        if (counts[num])
            counts[num]++;
        else
            counts[num] = 1;
    });
    // Find the number(s) with the highest occurrence
    var maxCount = Math.max.apply(Math, __spreadArray([], __read(Object.values(counts)), false));
    var mostPopularNumbers = Object.keys(counts).filter(function (key) { return counts[key] === maxCount; });
    // Return the most popular number or numbers if there's a tie
    return parseInt(mostPopularNumbers[0]);
};
var CategoryProducts = /** @class */ (function (_super) {
    __extends(CategoryProducts, _super);
    function CategoryProducts() {
        var _this = _super.apply(this, __spreadArray([], __read(arguments), false)) || this;
        Object.defineProperty(_this, "endpoint", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(_this, "loading", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(_this, "preloadingPages", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(_this, "io", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(_this, "cullingIo", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(_this, "visibleProducts", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: []
        });
        Object.defineProperty(_this, "idx", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(_this, "currentPage", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: window.location.search.includes('page=') ? parseInt(window.location.search.split('page=')[1]) : 1
        });
        Object.defineProperty(_this, "minPageLoaded", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: _this.currentPage
        });
        Object.defineProperty(_this, "hidden", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(_this, "previousPage", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(_this, "resetScroll", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function () {
                var lastCategories = store.lastCategories.get();
                var backup = lastCategories.find(function (s) {
                    return s.id === _this.el.dataset.id && s.stateIdx === _this.idx;
                });
                if (backup && backup.items.length) {
                    _this.refs.categoryList.innerHTML = '';
                    __spreadArray([], __read(backup.items), false).forEach(function (el) {
                        _this.refs.categoryList.append(el);
                    });
                    var pagination = forceArray(_this.modules.pagination);
                    var prevPagination = pagination.find(function (p) { return p === null || p === void 0 ? void 0 : p.el.classList.contains('previous'); });
                    var nextPagination = pagination.find(function (p) { return p === null || p === void 0 ? void 0 : p.el.classList.contains('next'); });
                    if (backup.minPageLoaded === 1)
                        prevPagination === null || prevPagination === void 0 ? void 0 : prevPagination.el.remove();
                    nextPagination === null || nextPagination === void 0 ? void 0 : nextPagination.setSeen(backup.seen);
                    _this.refresh();
                    _this.modules.revealList.reset();
                    browser.waitRepaint(function () {
                        browser.waitRepaint(function () {
                            setTimeout(function () {
                                document.scrollingElement.scrollTop = backup.top;
                            }, ['Product', 'Category'].includes(_this.previousPage) ? 0 : frameToMs(7.5));
                        });
                    });
                }
                else {
                    scroll.resetScroll();
                }
            }
        });
        Object.defineProperty(_this, "onItemClick", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (e) {
                store.lastProduct.set(e.currentTarget.getAttribute('data-id') || e.currentTarget.getAttribute('data-slide-id'));
                _this.hidden = true;
            }
        });
        Object.defineProperty(_this, "savePos", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function () {
                var lastCategories = store.lastCategories.get();
                var items = Array.from(_this.el.querySelectorAll('.category__list .category__text,.category__list .category__product, .category__list .search-results__product, .catalog-push'));
                var seen = items.filter(function (el) { return el.classList.contains('category__product') ||
                    el.classList.contains('search-results__product'); }).length;
                if (lastCategories.length >= MAX_LAST_STATES)
                    lastCategories.shift();
                lastCategories.push({
                    items: items,
                    id: _this.el.dataset.id,
                    stateIdx: _this.idx,
                    seen: seen,
                    minPageLoaded: _this.minPageLoaded,
                    top: document.scrollingElement.scrollTop,
                    date: Date.now()
                });
                lastCategories.sort(function (a, b) { return b.date - a.date; });
                store.lastCategories.set(lastCategories);
                _this.hidden = true;
            }
        });
        Object.defineProperty(_this, "onPageLoaded", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (_a, pageIndex, direction) {
                var _b, _c;
                var _d, _e, _f, _g;
                var xhr = _a.currentTarget;
                _this.loading = false;
                _this.parent.pageManager.loading = false;
                var page = xhr.response;
                extractInfoFromXhr(xhr);
                xhr.onload = null;
                var items = Array.from(page.querySelectorAll('.category__list .category__text, .category__list .category__product, .category__list .search-results__product'));
                var allItems = Array.from(page.querySelectorAll('.category__list .category__text, .category__list .catalog-push,.category__list .category__product, .category__list .search-results__product'));
                if (direction === 1)
                    (_b = _this.refs.categoryList).append.apply(_b, __spreadArray([], __read(allItems), false));
                else
                    (_c = _this.refs.categoryList).prepend.apply(_c, __spreadArray([], __read(allItems), false));
                // this.el.querySelector('.pagination.next').outerHTML = page.querySelector('.pagination.next').outerHTML
                (_d = _this.refs.loadMore) === null || _d === void 0 ? void 0 : _d.setAttribute('href', (_e = page.querySelector('.pagination.next a')) === null || _e === void 0 ? void 0 : _e.getAttribute('href'));
                if (page.querySelector('.pagination.prev a'))
                    (_f = _this.refs.loadLess) === null || _f === void 0 ? void 0 : _f.setAttribute('href', (_g = page.querySelector('.pagination.prev a')) === null || _g === void 0 ? void 0 : _g.getAttribute('href'));
                forceArray(_this.modules.pagination).forEach(function (p) {
                    p.onPageLoaded(pageIndex, items.length, direction);
                });
                if (_this.preloadingPages)
                    return;
                _this.modules.revealList.reset();
                updatePageLinks();
                var parent = _this.parent;
                parent.pageManager.watch();
                browser.waitRepaint(function () {
                    _this.refresh();
                    _this.io.disconnect();
                    for (var i = 0; i < _this.refs.products.length; i++) {
                        _this.io.observe(_this.refs.products[i]);
                        _this.cullingIo.observe(_this.refs.products[i]);
                    }
                });
            }
        });
        return _this;
    }
    Object.defineProperty(CategoryProducts.prototype, "bindModules", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            _super.prototype.bindModules.call(this);
        }
    });
    Object.defineProperty(CategoryProducts.prototype, "pageName", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return 'CategoryProducts';
        }
    });
    Object.defineProperty(CategoryProducts.prototype, "initialized", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var _a;
            _super.prototype.initialized.call(this);
            this.idx = (_a = window.history.state) === null || _a === void 0 ? void 0 : _a.idx;
            if (this.refs.categoryList)
                this.endpoint = this.refs.categoryList.getAttribute('data-src');
            this.io = new IntersectionObserver(this.watchPageScrollChange.bind(this), { threshold: [1] });
            this.cullingIo = new IntersectionObserver(this.cullingCallback.bind(this), {
                rootMargin: '0px 0px 100px 0px'
            });
            if (this.refs.products) {
                for (var i = 0; i < this.refs.products.length; i++) {
                    this.io.observe(this.refs.products[i]);
                    this.cullingIo.observe(this.refs.products[i]);
                }
            }
        }
    });
    Object.defineProperty(CategoryProducts.prototype, "getModulesMap", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return {
                productCard: ['.search-results__product .product-card, .category__product:not(carousel-hidden) .product-card', ProductCard],
                catalogPush: ['.catalog-push__links, .catalog-push__edito', CatalogPush],
                revealList: ['.category__list', RevealList],
                pagination: ['.pagination', Pagination]
            };
        }
    });
    Object.defineProperty(CategoryProducts.prototype, "getModuleParams", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (el, componentConstructor) {
            return __assign(__assign({}, _super.prototype.getModuleParams.call(this, el, componentConstructor)), { pages: this.refs.categoryList.getAttribute('data-nb-pages'), current: this.refs.categoryList.getAttribute('data-page'), seen: Array.from(this.refs.categoryList.querySelectorAll('.category__product, .search-results__product')).length });
        }
    });
    Object.defineProperty(CategoryProducts.prototype, "cullingCallback", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (entries) {
            for (var i = 0; i < entries.length; i++) {
                if (entries[i].isIntersecting)
                    entries[i].target.classList.remove('carousel-hidden');
                else
                    entries[i].target.classList.add('carousel-hidden');
            }
            this.refresh();
        }
    });
    Object.defineProperty(CategoryProducts.prototype, "watchPageScrollChange", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (entries) {
            for (var i = 0; i < entries.length; i++) {
                var video = entries[i].target.querySelector('video');
                if (entries[i].isIntersecting) {
                    this.visibleProducts.push(entries[i].target);
                }
                else {
                    var index = this.visibleProducts.indexOf(entries[i].target);
                    if (index > -1)
                        this.visibleProducts.splice(index, 1);
                }
            }
            // determine the current page by getting the most popular page in data-page on visible products
            var pages = this.visibleProducts.map(function (p) { return p.getAttribute('data-page'); });
            // in the pages array, determine which values is the most present and output it
            if (pages.length) {
                var current = findMostPopularNumber(pages);
                if (current !== this.currentPage) {
                    this.currentPage = current;
                    if (this.currentPage > 1)
                        window.history.replaceState({ idx: this.idx }, '', this.endpoint + (~this.endpoint.indexOf('?') ? '&' : '?') + 'page=' + this.currentPage);
                    else if (!this.hidden)
                        window.history.replaceState({ idx: this.idx }, '', this.endpoint);
                }
            }
        }
    });
    Object.defineProperty(CategoryProducts.prototype, "bindEvents", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (add) {
            var _this = this;
            if (add === void 0) { add = true; }
            var emitterMethod = bindEmitterMethod(add);
            forceArray(this.modules.productCard || []).forEach(function (p) {
                p[emitterMethod]('click', _this.onItemClick);
            });
            forceArray(this.modules.catalogPush || []).forEach(function (p) {
                p[emitterMethod]('click', _this.onItemClick);
            });
            forceArray(this.modules.pagination || []).forEach(function (p) {
                p[emitterMethod]('load', function (page, direction) { return _this.loadNewPage(page, direction); });
            });
        }
    });
    Object.defineProperty(CategoryProducts.prototype, "loadNewPage", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (page, direction) {
            var _this = this;
            if (direction === void 0) { direction = 1; }
            if (this.loading)
                return;
            this.loading = true;
            this.parent.pageManager.loading = true;
            var link = this.endpoint + (~this.endpoint.indexOf('?') ? '&' : '?') + 'page=' + page;
            if (link) {
                return new Promise(function (resolve) {
                    var xhr = new XMLHttpRequest();
                    xhr.withCredentials = true;
                    xhr.responseType = 'document';
                    xhr.open('GET', link, true);
                    xhr.setRequestHeader('X-Fursac-Async', 'true');
                    xhr.onload = function (event) {
                        _this.minPageLoaded = Math.min(_this.minPageLoaded, page);
                        window.history.replaceState({ idx: _this.idx }, '', link);
                        _this.onPageLoaded(event, page, direction);
                        resolve();
                    };
                    xhr.send();
                });
            }
            else {
                return new Promise(function (resolve) {
                    console.warn('No endpoint set to load new page'); // eslint-disable-line
                    resolve();
                });
            }
        }
    });
    Object.defineProperty(CategoryProducts.prototype, "resize", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            _super.prototype.resize.call(this);
        }
    });
    return CategoryProducts;
}(InternalPage));
export default CategoryProducts;
