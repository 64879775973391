var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { defer } from 'lodash-es';
import router, { getRoot } from 'core/router';
import store from 'store';
import PanelPage from 'navigation/pages/PanelPage';
import promise from 'helpers/promise';
import { frameToMs } from 'helpers/easings';
import MainPageManager from './MainPageManager';
import CustomPageManager, { extractInfoFromXhr } from './CustomPageManager';
var VirtualPageManager = /** @class */ (function (_super) {
    __extends(VirtualPageManager, _super);
    function VirtualPageManager(store) {
        var args = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            args[_i - 1] = arguments[_i];
        }
        var _this = _super.apply(this, __spreadArray([], __read(args), false)) || this;
        Object.defineProperty(_this, "store", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        // Inspired by navigo
        Object.defineProperty(_this, "onLinkClicked", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (event) {
                var _a;
                var link = event.currentTarget;
                if (!link)
                    return;
                if ((event.ctrlKey || event.metaKey) && ((_a = link.tagName) === null || _a === void 0 ? void 0 : _a.toLowerCase()) === 'a')
                    return false;
                event.preventDefault();
                event.stopPropagation();
                // event.stopImmediatePropagation()
                var location = router.getLinkPath(link);
                _this.navigateTo(location.replace(/\/+$/, '').replace(/^\/+/, '/'));
            }
        });
        _this.main = false;
        _this.store = store;
        return _this;
    }
    Object.defineProperty(VirtualPageManager.prototype, "updatePageLinks", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (el) {
            var _this = this;
            var links = Array.from(el.querySelectorAll('[data-navigo]:not([data-navigo="parent"]), .data-navigo'));
            links.forEach(function (link) {
                if (link.hasListenerAttached)
                    return;
                link.addEventListener('click', _this.onLinkClicked);
                link.hasListenerAttached = true;
            });
        }
    });
    Object.defineProperty(VirtualPageManager.prototype, "initializeRoutes", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var _this = this;
            defer(function () {
                _this.store.listenAndStart(function (page) {
                    if (_this.disabled)
                        return;
                    _this.onRouteUpdate(page);
                });
                var defaultRoute = _this.container && _this.container.getAttribute('default-route');
                if (!_this.currentPage && _this.extractPage(_this.container)) {
                    // if (!defaultRoute && getHistory()?.length > 1) defaultRoute = store.path.get()
                    _this.store.value = defaultRoute || store.path.get();
                    if (!_this.store.value)
                        return;
                    return _this.initializePage();
                }
                if (!_this.store.value && !_this.currentPage)
                    if (defaultRoute)
                        _this.navigateTo(defaultRoute);
            });
        }
    });
    Object.defineProperty(VirtualPageManager.prototype, "forceRouteUpdate", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var args = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                args[_i] = arguments[_i];
            }
            var _a = __read(args, 1), pathName = _a[0];
            this.store.value = pathName;
            return this.pageLoaded.apply(this, __spreadArray([], __read(args), false));
        }
    });
    Object.defineProperty(VirtualPageManager.prototype, "cancelTransition", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            this.store.set(null);
            this.state.loading = false;
        }
    });
    Object.defineProperty(VirtualPageManager.prototype, "onRouteUpdate", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (pathName, requestOptions) {
            if (requestOptions === void 0) { requestOptions = {}; }
            if (!pathName && pathName !== '') {
                if (this.currentPage) {
                    this.previousPage = this.currentPage;
                    this.state.next = undefined;
                    this.currentPage = undefined;
                    this.hidePage();
                }
                return false;
            }
            return _super.prototype.onRouteUpdate.call(this, pathName, requestOptions);
        }
    });
    Object.defineProperty(VirtualPageManager.prototype, "removePage", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            if (!(this.defaultPageClass === PanelPage)) return [3 /*break*/, 2];
                            return [4 /*yield*/, promise.wait(frameToMs(60))];
                        case 1:
                            _a.sent();
                            _a.label = 2;
                        case 2:
                            if (!this.previousPage)
                                return [2 /*return*/];
                            if (!this.currentPage)
                                this.state.previous = null;
                            return [2 /*return*/, _super.prototype.removePage.call(this)];
                    }
                });
            });
        }
    });
    Object.defineProperty(VirtualPageManager.prototype, "load", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (pathName, options) {
            if (!~pathName.indexOf(getRoot()))
                pathName = getRoot() + pathName;
            return _super.prototype.load.call(this, pathName, options);
        }
    });
    Object.defineProperty(VirtualPageManager.prototype, "rewriteRoute", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (pathName, overwrite) {
            if (overwrite === void 0) { overwrite = true; }
            if (~pathName.indexOf(getRoot()))
                pathName = pathName.replace(getRoot(), '');
            if (overwrite) {
                this.store.value = pathName;
            }
            else {
                this.disabled = true;
                this.store.set(pathName);
                this.disabled = false;
            }
        }
    });
    Object.defineProperty(VirtualPageManager.prototype, "createPage", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (el, PageClass) {
            var page = MainPageManager.prototype.createPage.call(this, el, PageClass);
            this.updatePageLinks(page.el);
            return page;
        }
    });
    Object.defineProperty(VirtualPageManager.prototype, "navigateTo", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (pathName) {
            this.store.set(pathName);
        }
    });
    Object.defineProperty(VirtualPageManager.prototype, "refresh", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var pathName = this.store.value;
            this.store.value = '';
            this.store.set(pathName);
        }
    });
    Object.defineProperty(VirtualPageManager.prototype, "pageLoaded", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var args = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                args[_i] = arguments[_i];
            }
            return __awaiter(this, void 0, void 0, function () {
                var xhr, page;
                return __generator(this, function (_a) {
                    xhr = args[1];
                    page = xhr.response;
                    if (!page || !page.body || !page.body.innerHTML) {
                        extractInfoFromXhr(xhr);
                        document.documentElement.classList.remove('loading');
                        this.state.loading = false;
                        this.state.transitioning = false;
                        this.store.set(null);
                        return [2 /*return*/, false];
                    }
                    return [2 /*return*/, _super.prototype.pageLoaded.apply(this, __spreadArray([], __read(args), false))];
                });
            });
        }
    });
    Object.defineProperty(VirtualPageManager.prototype, "flush", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            this.store.unlisten();
        }
    });
    return VirtualPageManager;
}(CustomPageManager));
export default VirtualPageManager;
