var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { bindMethod } from 'helpers/bind';
import Component from 'navigation/component/Component';
import ProductCard from 'sections/product/partials/ProductCard';
import store from 'store';
var ColorSelector = /** @class */ (function (_super) {
    __extends(ColorSelector, _super);
    function ColorSelector() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(ColorSelector.prototype, "initialized", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            _super.prototype.initialized.call(this);
        }
    });
    Object.defineProperty(ColorSelector.prototype, "getModulesMap", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return __assign(__assign({}, _super.prototype.getModulesMap.call(this)), { card: ['.product-card', ProductCard] });
        }
    });
    Object.defineProperty(ColorSelector.prototype, "onMouseEnter", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (event) {
            var target = event.currentTarget;
            for (var i = 0; i < this.el.children.length; i++)
                this.el.children[i].classList.remove('hovered');
            target.classList.add('hovered');
        }
    });
    Object.defineProperty(ColorSelector.prototype, "onMouseLeave", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (event) {
            for (var i = 0; i < this.el.children.length; i++)
                this.el.children[i].classList.remove('hovered');
        }
    });
    Object.defineProperty(ColorSelector.prototype, "onClick", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (event) {
            var target = event.currentTarget;
            window.dispatchEvent(new CustomEvent('returns:update-color', {
                detail: {
                    id: this.el.dataset.id,
                    colorId: target.dataset.colorId,
                    code: target.dataset.code,
                    pattern: target.dataset.pattern,
                    articleId: target.dataset.articleId
                }
            }));
            store.panel.set(null);
        }
    });
    Object.defineProperty(ColorSelector.prototype, "bindEvents", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (add) {
            var _this = this;
            _super.prototype.bindEvents.call(this, add);
            var method = bindMethod(add);
            for (var i = 0; i < this.el.children.length; i++) {
                this.el.children[i][method]('mouseenter', function (e) { return _this.onMouseEnter(e); });
                this.el.children[i][method]('mouseleave', function (e) { return _this.onMouseLeave(e); });
                if (this.el.children[i].tagName === 'DIV')
                    this.el.children[i][method]('click', function (e) { return _this.onClick(e); });
            }
        }
    });
    return ColorSelector;
}(Component));
export default ColorSelector;
