var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { getRoot } from 'core/router';
import { getRouterData } from 'navigation/component/Component';
import store from 'store';
import tag from 'core/tag';
import PageManager from './PageManager';
var decode = function (str) {
    // Going backwards: from bytestream, to percent-encoding, to original string.
    return decodeURIComponent(atob(str)
        .split('')
        .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    })
        .join(''));
};
function executeTag(tag) {
    if (tag)
        eval(tag); // eslint-disable-line no-eval
}
export var extractInfoFromXhr = function (xhr) {
    var auth = xhr.getResponseHeader('X-Fursac-Auth');
    var cart = xhr.getResponseHeader('X-Fursac-Cart');
    var error = xhr.getResponseHeader('X-Fursac-Error');
    var message = xhr.getResponseHeader('X-Fursac-Message');
    var events = xhr.getResponseHeader('X-Fursac-Events');
    var panel = xhr.getResponseHeader('X-Fursac-Panel'); // || 'cart|http://jonas.local:8080/fr/commande/panier.html'
    if (error)
        error = decode(error);
    if (message)
        message = decode(message);
    if (events)
        events = atob(events);
    if (!xhr.infoChecked) {
        if (auth)
            store.logged.set(!!parseInt(auth));
        if (cart)
            store.cart.set(parseInt(cart));
        if (panel) {
            var routerData_1 = getRouterData(panel);
            if (routerData_1.tagName)
                tag.openPopin(routerData_1.tagName, 'panel');
            if (routerData_1.path) {
                setTimeout(function () {
                    store.panel.set(routerData_1.path.replace(getRoot(), ''));
                }, 300);
            }
        }
    }
    var page = xhr.response;
    var obj = extractInfo(page, { error: error, message: message });
    if (obj.message)
        message = obj.message;
    if (obj.error)
        error = obj.error;
    if (obj.events)
        events = obj.events;
    if (events)
        executeTag(events);
    if (obj.tracking)
        executeTag(obj.tracking);
    xhr.infoChecked = true;
    return {
        panel: panel,
        auth: auth,
        cart: cart,
        error: error,
        message: message,
        events: events
    };
};
function extractInfo(page, _a) {
    var _b;
    var _c = _a === void 0 ? {} : _a, _d = _c.error, error = _d === void 0 ? null : _d, _e = _c.message, message = _e === void 0 ? null : _e;
    var events = '';
    var tracking = '';
    var cartMessages = [];
    if (page) {
        if (!error || !message) {
            var errorMessage = page.querySelector('.error-message');
            var successMessage = page.querySelector('.success-message');
            cartMessages = Array.from(page.querySelectorAll('.cart-message'));
            if (errorMessage) {
                error = errorMessage.innerHTML;
                errorMessage.parentNode.removeChild(errorMessage);
            }
            else if (successMessage) {
                message = successMessage.innerHTML;
                successMessage.parentNode.removeChild(successMessage);
            }
        }
        var ga4Events = page.querySelector('script#ga4-events');
        if (ga4Events) {
            events = ga4Events.innerHTML;
            ga4Events.parentNode.removeChild(ga4Events);
        }
        var trackingScripts = page.querySelectorAll('script.tracking');
        if (trackingScripts.length) {
            for (var i = 0; i < trackingScripts.length; i++) {
                tracking += trackingScripts[i].innerHTML;
                trackingScripts[i].parentNode.removeChild(trackingScripts[i]);
            }
        }
        var listNameFields = Array.from(page.querySelectorAll('input[data-ref=listName][value=""]'));
        if (listNameFields.length) {
            for (var i = 0; i < listNameFields.length; i++)
                listNameFields[i].value = localStorage.getItem('list_name');
        }
    }
    if (error || message || cartMessages.length > 0) {
        if (cartMessages.length > 0) {
            for (var i = 0; i < cartMessages.length; i++) {
                store.message.set({
                    error: !!error,
                    cart: true,
                    text: error || message || cartMessages[i].innerHTML
                });
                (_b = page.querySelector('.cart-messages')) === null || _b === void 0 ? void 0 : _b.remove();
            }
        }
        else {
            store.message.set({
                error: !!error,
                text: error || message,
                cart: false
            });
        }
    }
    return {
        error: error,
        message: message,
        events: events,
        tracking: tracking
    };
}
var CustomPageManager = /** @class */ (function (_super) {
    __extends(CustomPageManager, _super);
    function CustomPageManager() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(CustomPageManager.prototype, "initializePage", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, _super.prototype.initializePage.call(this)];
                        case 1:
                            _a.sent();
                            extractInfo(this.currentPage.el);
                            return [2 /*return*/];
                    }
                });
            });
        }
    });
    Object.defineProperty(CustomPageManager.prototype, "pageLoaded", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (pathName, xhr, requestOptions) {
            return __awaiter(this, void 0, void 0, function () {
                var data, page, currentRouter, skus;
                var _a;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            data = extractInfoFromXhr(xhr);
                            page = xhr === null || xhr === void 0 ? void 0 : xhr.response;
                            if ((data.error || data.message) && !((_a = page === null || page === void 0 ? void 0 : page.body) === null || _a === void 0 ? void 0 : _a.innerHTML)) {
                                this.cancelTransition();
                                return [2 /*return*/, false];
                            }
                            return [4 /*yield*/, _super.prototype.pageLoaded.call(this, pathName, xhr, requestOptions)];
                        case 1:
                            currentRouter = _b.sent();
                            if (currentRouter === true && this.state.next !== 'CheckoutSummary') {
                                try {
                                    // @ts-ignore
                                    blueshift.pageload(); // eslint-disable-line no-undef
                                    skus = xhr.getResponseHeader('X-Fursac-SKUs');
                                    // @ts-ignore
                                    if (skus)
                                        blueshift.track('view', { products: JSON.parse(atob(skus)) }); // eslint-disable-line no-undef
                                }
                                catch (error) { }
                            }
                            return [2 /*return*/, true];
                    }
                });
            });
        }
    });
    Object.defineProperty(CustomPageManager.prototype, "extractPageFromXHR", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (root) {
            return _super.prototype.extractPageFromXHR.call(this, root);
        }
    });
    Object.defineProperty(CustomPageManager.prototype, "extractPageInfo", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (page) {
            return _super.prototype.extractPageInfo.call(this, page);
        }
    });
    return CustomPageManager;
}(PageManager));
export default CustomPageManager;
