var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import Component from 'navigation/component/Component';
import store from 'store/store';
import HomeMedia from './HomeMedia';
import HomeDiptych from './HomeDiptych';
import HomeLinks from './HomeLinks';
import BlockProducts from 'sections/catalog/partials/BlockProducts';
var HomeBlocks = /** @class */ (function (_super) {
    __extends(HomeBlocks, _super);
    function HomeBlocks() {
        var _this = _super.apply(this, __spreadArray([], __read(arguments), false)) || this;
        Object.defineProperty(_this, "lastVisibleBlocks", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: []
        });
        Object.defineProperty(_this, "currentBlock", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(_this, "onIntersection", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (entries) {
                var _a, _b;
                entries.forEach(function (entry) {
                    var lastIndex = _this.lastVisibleBlocks.findIndex(function (e) { return e.target === entry.target; });
                    if (entry.isIntersecting) {
                        if (!~lastIndex)
                            _this.lastVisibleBlocks.push(entry);
                        else
                            _this.lastVisibleBlocks[lastIndex] = entry;
                    }
                    else {
                        if (~lastIndex)
                            _this.lastVisibleBlocks.splice(lastIndex, 1);
                    }
                });
                var scores = _this.lastVisibleBlocks.map(function (entry) {
                    var score = entry.intersectionRect.height;
                    return {
                        score: score,
                        entry: entry
                    };
                });
                var orderedScores = scores
                    .slice(0)
                    .sort(function (a, b) { return b.score - a.score; });
                var first = orderedScores[0];
                if ((first === null || first === void 0 ? void 0 : first.entry) !== _this.currentBlock) {
                    var parent_1 = _this.parent;
                    if (parent_1.pageManager.state.transitioning)
                        return;
                    _this.currentBlock = first === null || first === void 0 ? void 0 : first.entry;
                    var headerValue = ((_b = (_a = _this.currentBlock) === null || _a === void 0 ? void 0 : _a.target) === null || _b === void 0 ? void 0 : _b.getAttribute('data-header')) || 'solid';
                    switch (headerValue) {
                        case 'solid':
                            store.headerVariants.set(['solid']);
                            break;
                        case 'light':
                            store.headerVariants.set(['light', 'transparent']);
                            break;
                        case 'dark':
                            store.headerVariants.set(['dark', 'transparent']);
                            break;
                        default:
                            store.headerVariants.set(['solid']);
                            break;
                    }
                }
            }
        });
        return _this;
    }
    Object.defineProperty(HomeBlocks.prototype, "getModulesMap", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return __assign(__assign({}, _super.prototype.getModulesMap.call(this)), { homeMedia: ['.home-media', HomeMedia], homeDiptych: ['.home-diptych', HomeDiptych], homeLinks: ['.home-links', HomeLinks], homeProducts: ['.block-products', BlockProducts] });
        }
    });
    Object.defineProperty(HomeBlocks.prototype, "initialized", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            this.offsets = [];
            this.headerBlocks = Array.from(this.el.children).filter(function (block) {
                return block.hasAttribute('data-header');
            });
            _super.prototype.initialized.call(this);
        }
    });
    Object.defineProperty(HomeBlocks.prototype, "getModuleParams", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (el, componentConstructor) {
            {
                return {
                    parent: this.parent,
                };
            }
        }
    });
    Object.defineProperty(HomeBlocks.prototype, "bindEvents", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (add) {
            var _this = this;
            if (add === void 0) { add = true; }
            _super.prototype.bindEvents.call(this);
            if (add) {
                this.intersectionObserver = new IntersectionObserver(this.onIntersection, {
                    threshold: Array(10).fill(true).map(function (_, i) { return i / 1000; }),
                    rootMargin: '0px 0px -90% 0px'
                });
                this.headerBlocks.forEach(function (block) { return _this.intersectionObserver.observe(block); });
            }
            else {
                this.intersectionObserver.disconnect();
            }
        }
    });
    Object.defineProperty(HomeBlocks.prototype, "resize", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            _super.prototype.resize.call(this);
        }
    });
    Object.defineProperty(HomeBlocks.prototype, "flush", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            _super.prototype.flush.call(this);
            store.darkPage.set(false);
        }
    });
    return HomeBlocks;
}(Component));
export default HomeBlocks;
