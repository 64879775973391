var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import SteppedManualInfiniteCarousel from 'components/infinite/SteppedManualInfiniteCarousel';
import { bindEmitterMethod, bindMethod } from 'helpers/bind';
import Component, { forceArray } from 'navigation/component/Component';
var CatalogPush = /** @class */ (function (_super) {
    __extends(CatalogPush, _super);
    function CatalogPush() {
        var _this = _super.apply(this, __spreadArray([], __read(arguments), false)) || this;
        Object.defineProperty(_this, "totalDuration", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: 0
        });
        Object.defineProperty(_this, "currentDuration", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: 0
        });
        Object.defineProperty(_this, "onProgress", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (progress) {
                if (_this.refs.track)
                    _this.refs.track.style.transform = "translate3d(0, 0, 0) scaleX(".concat(progress, ")");
            }
        });
        return _this;
    }
    Object.defineProperty(CatalogPush.prototype, "initialized", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            _super.prototype.initialized.call(this);
            this.totalDuration = this.refs.slides.length * CatalogPush.slideDuration;
        }
    });
    Object.defineProperty(CatalogPush.prototype, "getModulesMap", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            if (this.refs.slides.length > 1) {
                return {
                    carousel: ['.catalog-push__slider', SteppedManualInfiniteCarousel]
                };
            }
            else {
                return {};
            }
        }
    });
    Object.defineProperty(CatalogPush.prototype, "bindEvents", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (add) {
            var _this = this;
            var method = bindMethod(add);
            var emitterMethod = bindEmitterMethod(add);
            if (this.refs.more) {
                this.refs.more.forEach(function (more, index) {
                    more[method]('click', function () { return _this.onMore(index); });
                });
            }
            if (this.refs.less) {
                this.refs.less.forEach(function (less, index) {
                    less[method]('click', function () { return _this.onLess(index); });
                });
            }
            forceArray(this.refs.ctas).forEach(function (cta) {
                if (cta)
                    cta[method]('click', function (e) { return _this.onCTAClick(e); });
            });
            if (this.modules.carousel)
                this.modules.carousel[emitterMethod]('progress', this.onProgress);
        }
    });
    Object.defineProperty(CatalogPush.prototype, "onCTAClick", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (e) {
            this.emit('click', e);
        }
    });
    Object.defineProperty(CatalogPush.prototype, "onMore", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (index) {
            this.refs.body[index].classList.add('hidden');
            this.refs.fullBody[index].classList.remove('hidden');
        }
    });
    Object.defineProperty(CatalogPush.prototype, "onLess", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (index) {
            if (this.refs.body[index])
                this.refs.body[index].classList.remove('hidden');
            if (this.refs.fullBody[index])
                this.refs.fullBody[index].classList.add('hidden');
        }
    });
    Object.defineProperty(CatalogPush, "slideDuration", {
        enumerable: true,
        configurable: true,
        writable: true,
        value: 8000
    });
    return CatalogPush;
}(Component));
export default CatalogPush;
