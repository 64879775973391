var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import Component from 'navigation/component/Component';
import store from 'store';
import { bindEmitterMethod, bindMethod } from 'helpers/bind';
import mqStore from 'store/mqStore';
import anime from 'animejs';
import scroll from 'core/scroll';
import MenuFirstPanel from './MenuFirstPanel';
import MenuSecondPanel from './MenuSecondPanel';
import { durations } from './motion';
var Menu = /** @class */ (function (_super) {
    __extends(Menu, _super);
    function Menu() {
        var _this = _super.apply(this, __spreadArray([], __read(arguments), false)) || this;
        Object.defineProperty(_this, "mobile", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(_this, "_opened", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(_this, "motions", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: []
        });
        Object.defineProperty(_this, "onMqChange", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (mobile) {
                _this.mobile = mobile;
                store.drawer.set(null);
            }
        });
        Object.defineProperty(_this, "onSecondPanelOpenBegin", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function () {
                if (_this.mobile)
                    _this.modules.firstPanel.leave();
            }
        });
        Object.defineProperty(_this, "onFirstPanelOpenBegin", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function () {
                _this.modules.secondPanel.forceClose();
                _this.modules.secondPanel.reset();
            }
        });
        Object.defineProperty(_this, "onTriggerSecondPanel", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function () {
                var subcategory = store.subcategory.get();
                if (subcategory && _this.modules.secondPanel.list === null) {
                    if (_this.mobile)
                        _this.modules.firstPanel.open(true);
                    _this.modules.secondPanel.autoOpen = true;
                    _this.modules.secondPanel.list = store.category.get();
                    _this.modules.secondPanel.onLinkOut();
                }
            }
        });
        Object.defineProperty(_this, "onDrawerChange", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (drawer) { return __awaiter(_this, void 0, void 0, function () {
                var isOpen;
                return __generator(this, function (_a) {
                    this.cancelMotions();
                    isOpen = drawer === 'menu';
                    if (isOpen) {
                        scroll.lock();
                        if (store.category.get() !== null) {
                            if (store.subcategory.get() !== null) {
                                this.modules.firstPanel.category = store.category.get();
                                this.modules.firstPanel.onItemOut();
                            }
                            if (!this.mobile || store.subcategory.get() === null)
                                this.modules.firstPanel.open();
                            else
                                this.onTriggerSecondPanel();
                        }
                        else {
                            this.modules.firstPanel.open();
                        }
                        this.opened = true;
                    }
                    else {
                        scroll.unlock();
                        if (this.opened) {
                            this.opened = false;
                            if (this.modules.secondPanel.list)
                                this.closeBothPanels();
                            else
                                this.modules.firstPanel.close();
                        }
                    }
                    this.refs.overlay.classList.toggle('visible', isOpen);
                    return [2 /*return*/];
                });
            }); }
        });
        Object.defineProperty(_this, "onCategoryUpdate", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (category) {
                if (_this.mobile && category === null) {
                    _this.modules.firstPanel.enter();
                    _this.modules.firstPanel.category = null;
                    _this.modules.firstPanel.onItemOut();
                }
                _this.modules.secondPanel.list = category;
            }
        });
        Object.defineProperty(_this, "closeBothPanels", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function () { return __awaiter(_this, void 0, void 0, function () {
                var inners, m0, m;
                var _this = this;
                return __generator(this, function (_a) {
                    this.cancelMotions();
                    inners = [Array.from(this.modules.firstPanel.el.children), Array.from(this.modules.secondPanel.el.children)].flat();
                    m0 = anime({
                        targets: [inners],
                        opacity: {
                            value: 0,
                            duration: durations.FIRST_PANEL_OPACITY_OUT / 2,
                            easing: 'linear'
                        }
                    });
                    m = anime({
                        targets: [this.modules.firstPanel.el, this.modules.secondPanel.el],
                        translateX: {
                            value: mqStore.tabletPortrait.get() ? [0, '-100%'] : [0, '-50%'],
                            duration: durations.FIRST_PANEL_TRANSLATE_OUT,
                            easing: 'easeInSine'
                        },
                        opacity: {
                            value: [1, 0],
                            duration: durations.FIRST_PANEL_OPACITY_OUT,
                            delay: (durations.FIRST_PANEL_OPACITY_OUT) / 2,
                            easing: 'linear'
                        },
                        complete: function () {
                            _this.el.style.transform = '';
                            _this.el.style.opacity = '';
                            _this.modules.firstPanel.reset();
                            _this.modules.secondPanel.reset();
                            _this.modules.firstPanel.opened = false;
                            _this.modules.secondPanel.list = null;
                            _this.modules.firstPanel.category = null;
                            _this.modules.firstPanel.onItemOut();
                            _this.modules.secondPanel.onListOut();
                            _this.modules.firstPanel.onListOut();
                            _this.modules.secondPanel.modules.inners.forEach(function (inner) { return inner.reset(); });
                            inners.forEach(function (inner) {
                                inner.removeAttribute('style');
                            });
                        }
                    });
                    this.motions.push(m0, m);
                    return [2 /*return*/];
                });
            }); }
        });
        Object.defineProperty(_this, "cancelMotions", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function () {
                _this.modules.firstPanel.cancelMotions();
                _this.modules.secondPanel.cancelMotions();
                _this.motions.forEach(function (m) { return m.pause(); });
                _this.motions = [];
                var inners = [Array.from(_this.modules.firstPanel.el.children), Array.from(_this.modules.secondPanel.el.children)].flat();
                inners.forEach(function (inner) {
                    inner.removeAttribute('style');
                });
            }
        });
        return _this;
    }
    Object.defineProperty(Menu.prototype, "opened", {
        get: function () {
            return this._opened;
        },
        set: function (value) {
            this._opened = value;
            this.el.classList.toggle('visible', value);
            document.body.classList.toggle('menu-opened', value);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Menu.prototype, "bindEvents", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (add) {
            var _this = this;
            var emitterMethod = bindEmitterMethod(add);
            var method = bindMethod(add);
            store.drawer.toggleListener(add, this.onDrawerChange);
            this.modules.firstPanel[emitterMethod]('category:change', this.onCategoryUpdate);
            this.modules.secondPanel[emitterMethod]('category:change', this.onCategoryUpdate);
            this.modules.firstPanel[emitterMethod]('open:complete', this.onTriggerSecondPanel);
            this.modules.firstPanel[emitterMethod]('open:begin', this.onFirstPanelOpenBegin);
            this.modules.secondPanel[emitterMethod]('open:begin', this.onSecondPanelOpenBegin);
            mqStore.tabletPortrait.listenAndStart(function (mobile) { return _this.onMqChange(mobile); });
            this.refs.overlay[method]('click', function () { return store.drawer.set(null); });
            store.panel.toggleListener(add, function () { return store.drawer.set(null); });
        }
    });
    Object.defineProperty(Menu.prototype, "getModulesMap", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return {
                firstPanel: ['.menu-level__first', MenuFirstPanel],
                secondPanel: ['.menu-level__second', MenuSecondPanel]
            };
        }
    });
    return Menu;
}(Component));
export default Menu;
