var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import NativeSelect from 'components/native-select/NativeSelect';
import { bindEmitterMethod, bindMethod } from 'helpers/bind';
import Component, { forceArray } from 'navigation/component/Component';
var ReturnsItemRow = /** @class */ (function (_super) {
    __extends(ReturnsItemRow, _super);
    function ReturnsItemRow() {
        var _this = _super.apply(this, __spreadArray([], __read(arguments), false)) || this;
        Object.defineProperty(_this, "_choice", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(_this, "_reason", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(_this, "id", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(_this, "handleChoiceChange", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (e) {
                var target = e.currentTarget;
                var other = _this.refs.toggleChoices.find(function (el) { return el !== target; });
                if (target.checked) {
                    _this.choice = target.value;
                    other.checked = false;
                }
                else {
                    _this.choice = null;
                }
                if (_this.choice === null || _this.choice === 'return') {
                    forceArray(_this.refs.sizeInput).forEach(function (input) {
                        input.removeAttribute('data-required');
                    });
                }
                forceArray(_this.refs.sizeInput).forEach(function (input) {
                    var _a, _b;
                    (_b = (_a = input.parentElement) === null || _a === void 0 ? void 0 : _a.parentElement) === null || _b === void 0 ? void 0 : _b.classList.remove('error');
                });
                _this.emit('update:choice', _this.choice);
            }
        });
        Object.defineProperty(_this, "handleReasonChange", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (value) {
                forceArray(_this.refs.sizeInput).forEach(function (input) {
                    if (_this.choice === 'exchange')
                        input.setAttribute('data-required', 'true');
                    else
                        input.removeAttribute('data-required');
                });
                forceArray(_this.refs.colorInput).forEach(function (input) {
                    if (_this.choice === 'exchange')
                        input.setAttribute('data-required', 'true');
                    else
                        input.removeAttribute('data-required');
                });
                _this.reason = (value === null || value === void 0 ? void 0 : value.value) || null;
            }
        });
        Object.defineProperty(_this, "updateRequirements", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function () {
                if (_this.choice === 'exchange')
                    _this.modules.reasons[0].refs.select.setAttribute('data-required', 'true');
                if (_this.choice === 'return')
                    _this.modules.reasons[1].refs.select.setAttribute('data-required', 'true');
                forceArray(_this.refs.sizeInput).forEach(function (input) {
                    if (_this.choice === 'exchange')
                        input.setAttribute('data-required', 'true');
                    else
                        input.removeAttribute('data-required');
                });
                _this.emit('revalidate');
            }
        });
        Object.defineProperty(_this, "handleSizeUpdate", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (e) {
                var _a = e.detail, id = _a.id, sizeId = _a.sizeId, sizeName = _a.sizeName;
                if (id === _this.id) {
                    _this.refs.sizeLabel[0].textContent = sizeName;
                    _this.refs.sizeInput[0].value = sizeId;
                    _this.refs.sizeInput[0].dispatchEvent(new Event('change'));
                    var href = _this.refs.toggleSizeSelector[0].getAttribute('data-panel');
                    href = href.replace(/current=\d+/, "current=".concat(sizeId));
                    _this.refs.toggleSizeSelector[0].setAttribute('data-panel', href);
                    _this.emit('update:size');
                }
            }
        });
        Object.defineProperty(_this, "handleColorUpdate", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (e) {
                var _a = e.detail, id = _a.id, colorId = _a.colorId, code = _a.code, articleId = _a.articleId, pattern = _a.pattern;
                console.log(colorId);
                if (id === _this.id) {
                    console.log(id, _this.id);
                    _this.refs.colorPattern.innerHTML = pattern;
                    _this.refs.colorSwatch.style.backgroundColor = code;
                    _this.refs.colorInput[0].value = colorId;
                    _this.refs.colorInput[0].dispatchEvent(new Event('change'));
                    var href = _this.refs.toggleColorSelector[0].getAttribute('data-panel');
                    href = href.replace(_this.el.dataset.recordId, articleId);
                    _this.refs.toggleColorSelector[0].setAttribute('data-panel', href);
                    _this.emit('update:color');
                }
            }
        });
        return _this;
    }
    Object.defineProperty(ReturnsItemRow.prototype, "choice", {
        get: function () {
            return this._choice;
        },
        set: function (value) {
            var previous = this._choice;
            this.reset();
            this._choice = value;
            if (value) {
                this.el.setAttribute('data-choice', value);
                this.updateRequirements();
            }
            else {
                this.el.removeAttribute('data-choice');
            }
            if (previous !== value)
                this.emit('update:choice', value);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ReturnsItemRow.prototype, "reason", {
        get: function () {
            return this._reason;
        },
        set: function (value) {
            var previous = this._reason;
            this._reason = value;
            if (previous !== value)
                this.emit('update:reason', value);
            if (value)
                this.el.setAttribute('data-reason', value);
            else
                this.el.removeAttribute('data-reason');
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ReturnsItemRow.prototype, "getModulesMap", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return {
                reasons: ['.native-select', NativeSelect]
            };
        }
    });
    Object.defineProperty(ReturnsItemRow.prototype, "initialized", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var _this = this;
            var _a, _b;
            _super.prototype.initialized.call(this);
            this.id = this.el.dataset.id;
            // add choice change call to detect choices if provided by session
            (_a = this.refs.toggleChoices) === null || _a === void 0 ? void 0 : _a.forEach(function (el) {
                if (el.checked) {
                    _this._choice = el.value;
                    _this.el.setAttribute('data-choice', el.value);
                    _this.updateRequirements();
                }
            });
            (_b = this.modules.reasons) === null || _b === void 0 ? void 0 : _b.forEach(function (select) {
                var _a;
                // this.this.handleReasonChange({ c })
                if ((_a = select.refs) === null || _a === void 0 ? void 0 : _a.select.value) {
                    _this._reason = select.refs.select.value;
                    _this.el.setAttribute('data-reason', select.refs.select.value);
                }
            });
        }
    });
    Object.defineProperty(ReturnsItemRow.prototype, "bindEvents", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (add) {
            var _this = this;
            var _a, _b;
            var method = bindMethod(add);
            var emitterMethod = bindEmitterMethod(add);
            (_a = this.refs.toggleChoices) === null || _a === void 0 ? void 0 : _a.forEach(function (el) {
                el[method]('change', _this.handleChoiceChange);
            });
            (_b = this.modules.reasons) === null || _b === void 0 ? void 0 : _b.forEach(function (select) {
                select[emitterMethod]('change', _this.handleReasonChange);
            });
            window[method]('returns:update-size', this.handleSizeUpdate);
            window[method]('returns:update-color', this.handleColorUpdate);
        }
    });
    Object.defineProperty(ReturnsItemRow.prototype, "reset", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            this.reason = null;
            this.modules.reasons.forEach(function (select) {
                select.reset();
            });
            this.refs.sizeInput.forEach(function (input) {
                if (!(input.dataset.os))
                    input.value = '';
            });
            this.refs.sizeLabel.forEach(function (label) {
                if (label.dataset.os === undefined)
                    label.innerHTML = '';
            });
            this.emit('revaliate');
        }
    });
    return ReturnsItemRow;
}(Component));
export default ReturnsItemRow;
