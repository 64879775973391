var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { bindMethod } from 'helpers/bind';
import Component from 'navigation/component/Component';
var Pagination = /** @class */ (function (_super) {
    __extends(Pagination, _super);
    function Pagination(el, options) {
        var _this = _super.call(this, el, options) || this;
        Object.defineProperty(_this, "previous", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(_this, "_current", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(_this, "nextPageFrom", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(_this, "pages", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(_this, "items", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: 0
        });
        Object.defineProperty(_this, "_seen", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: 0
        });
        _this.pages = parseInt(options.pages);
        _this.current = parseInt(options.current);
        _this.nextPageFrom = _this.current;
        _this.items = parseInt(_this.el.dataset.total);
        _this.seen = options.seen;
        return _this;
    }
    Object.defineProperty(Pagination.prototype, "current", {
        get: function () {
            return this._current;
        },
        set: function (value) {
            this.previous = this._current;
            this._current = value;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Pagination.prototype, "seen", {
        get: function () {
            return this._seen;
        },
        set: function (value) {
            var _a;
            this._seen = value;
            if (this.refs.seenCount)
                this.refs.seenCount.innerHTML = value.toString();
            if (this.refs.trackBar)
                this.refs.trackBar.style.setProperty('--track-width', "".concat((value / this.items) * 100, "%"));
            if (value === this.items)
                // in case we navigate forward to a first page BUT we have stored all the items
                (_a = this.refs.loadMore) === null || _a === void 0 ? void 0 : _a.remove();
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Pagination.prototype, "initialized", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            _super.prototype.initialized.call(this);
        }
    });
    Object.defineProperty(Pagination.prototype, "setSeen", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (value) {
            this.seen = value;
        }
    });
    Object.defineProperty(Pagination.prototype, "bindEvents", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (add) {
            var _this = this;
            if (add === void 0) { add = true; }
            var method = bindMethod(add);
            if (this.refs.loadMore)
                this.refs.loadMore[method]('click', function (e) { return _this.load(e); });
            if (this.refs.loadLess)
                this.refs.loadLess[method]('click', function (e) { return _this.load(e); });
        }
    });
    Object.defineProperty(Pagination.prototype, "load", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (event) {
            event.preventDefault();
            event.stopPropagation();
            event.stopImmediatePropagation();
            var direction = parseInt(event.currentTarget.dataset.delta);
            var from = 0;
            if (direction < 0)
                from = this.current;
            else
                from = this.nextPageFrom;
            if (from + direction > 0 && from + direction <= this.pages) {
                this.emit('load', from + direction, direction);
                this.refs[direction === 1 ? 'loadMore' : 'loadLess'].classList.add('loading');
            }
        }
    });
    Object.defineProperty(Pagination.prototype, "onPageLoaded", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (page, items, direction) {
            if (page > this.nextPageFrom)
                this.nextPageFrom = page;
            this.current = page;
            this.bindRefs();
            this.seen += items;
            if (page === 1)
                if (this.refs.loadLess)
                    this.refs.loadLess.remove();
            if (page === this.pages)
                if (this.refs.loadMore)
                    this.refs.loadMore.remove();
            if (this.refs.loadLess)
                this.refs.loadLess.classList.remove('loading');
            if (this.refs.loadMore)
                this.refs.loadMore.classList.remove('loading');
        }
    });
    return Pagination;
}(Component));
export default Pagination;
