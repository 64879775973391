import { createStore } from 'helpers/state';
import tag from 'core/tag';
var store = createStore({
    lang: 'fr',
    path: null,
    loading: 1,
    message: null,
    fullPath: null,
    // CHECKOUT
    checkoutStep: null,
    checkoutPickup: null,
    checkoutSummaryOpened: false,
    persistantOverlay: false,
    // RETURNS
    returnsStep: null,
    // ROUTERS
    routers: {},
    menu: null,
    panel: null,
    drawer: null,
    previousDrawer: null,
    search: null,
    popin: null,
    filters: false,
    infosAccordion: null,
    lastCategories: [],
    lastProduct: null,
    // HEADERS
    cart: null,
    logged: null,
    alternateLinks: null,
    darkPage: false,
    darkMode: false,
    // PRODUCT
    scrollDown: false,
    overlay: false,
    overlayStrength: 'strength-1',
    headerVariants: [],
    gtagId: '',
    previousHeaderVariants: [],
    category: null,
    subcategory: null
});
store.drawer.listen(function (value) {
    if (value)
        store.previousDrawer.set(store.drawer.get());
});
store.menu.listen(function (value) { if (!value)
    tag.closePopin('menu'); });
store.search.listen(function (value) { if (!value)
    tag.closePopin('search'); });
store.panel.listen(function (value) { if (!value)
    tag.closePopin('panel'); });
store.panel.listen(function (value) { if (!value)
    tag.closePopin('panel'); });
store.popin.listen(function (value) { if (!value)
    tag.closePopin('popin'); });
export default store;
