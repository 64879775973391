var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { compact, defer } from 'lodash-es';
import { TinyEmitter } from 'tiny-emitter';
import router, { getRoot, getUri, updatePageLinks } from 'core/router';
import pageMap from 'core/pageMap';
import store from 'store';
import promise from 'helpers/promise';
import mqStore from 'store/mqStore';
var PageManager = /** @class */ (function (_super) {
    __extends(PageManager, _super);
    function PageManager(container, pageSelector, defaultPageClass, parameters, _a) {
        if (parameters === void 0) { parameters = {}; }
        var _b = _a === void 0 ? {} : _a, _c = _b.loadBeforeHide, loadBeforeHide = _c === void 0 ? true : _c, // ⚠ Should be true if you want to use virtual page manager
        _d = _b.crossTransition, // ⚠ Should be true if you want to use virtual page manager
        crossTransition = _d === void 0 ? true : _d;
        var _this = _super.call(this) || this;
        Object.defineProperty(_this, "main", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: true
        });
        Object.defineProperty(_this, "disabled", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(_this, "loadBeforeHide", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(_this, "crossTransition", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(_this, "currentPage", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(_this, "previousPage", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(_this, "nextPageInfo", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(_this, "xhr", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(_this, "pathName", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(_this, "newPathName", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(_this, "container", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(_this, "pageSelector", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(_this, "defaultPageClass", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(_this, "parameters", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(_this, "state", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: {
                transitioning: false,
                loading: false,
                init: false
            }
        });
        Object.defineProperty(_this, "pageShown", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function () { return __awaiter(_this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            this.currentPage.emit('show:complete');
                            if (!(this.previousPage && this.previousPage.isCheckoutInternalPage && !mqStore.tabletPortrait.get())) return [3 /*break*/, 2];
                            return [4 /*yield*/, promise.wait(1000)];
                        case 1:
                            _a.sent();
                            _a.label = 2;
                        case 2:
                            if (this.crossTransition && this.previousPage)
                                this.removePage();
                            this.transitionComplete();
                            return [2 /*return*/];
                    }
                });
            }); }
        });
        Object.defineProperty(_this, "pageHidden", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function () {
                if (!_this.loadBeforeHide && _this.state.loading)
                    return;
                if (_this.crossTransition) {
                    if (!_this.currentPage || _this.currentPage.state.shown) {
                        _this.removePage();
                        _this.transitionComplete();
                    }
                }
                else {
                    _this.removePage();
                    if (_this.loadBeforeHide || _this.currentPage)
                        _this.showPage();
                }
            }
        });
        Object.defineProperty(_this, "resize", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function () {
                if (_this.currentPage)
                    _this.currentPage.resize();
                if (_this.previousPage)
                    _this.previousPage.resize();
            }
        });
        // Page Parameters
        _this.parameters = parameters;
        // Transition Parameters
        _this.loadBeforeHide = loadBeforeHide;
        _this.crossTransition = crossTransition;
        // Manager Parameters
        _this.container = container;
        _this.defaultPageClass = defaultPageClass;
        _this.pageSelector = pageSelector;
        _this.initializeRoutes();
        return _this;
    }
    Object.defineProperty(PageManager.prototype, "initializePage", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return __awaiter(this, void 0, void 0, function () {
                var el, pageClass, prev;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            if (this.main) {
                                this.extractPageInfo(document);
                                this.pathName = getUri();
                                if (this.nextPageInfo && this.nextPageInfo.bodyClassName.indexOf('error404') > -1)
                                    this.pathName = '404';
                            }
                            el = this.extractPage(this.container);
                            if (!el)
                                return [2 /*return*/];
                            return [4 /*yield*/, this.getPageClass(el)];
                        case 1:
                            pageClass = _a.sent();
                            this.currentPage = this.createPage(el, pageClass);
                            prev = this.state.previous;
                            if (!this.currentPage)
                                return [2 /*return*/];
                            updatePageLinks();
                            document.querySelectorAll('a[href="#"]:not([class*="anbu"]):not([id*="anbu"]):not([id="store-reservation"])').forEach(function (a) { a.classList.add('ignored-link'); });
                            return [4 /*yield*/, promise.wait(1)];
                        case 2:
                            _a.sent();
                            return [4 /*yield*/, this.currentPage.askPreload(prev)];
                        case 3:
                            _a.sent();
                            this.state.init = true;
                            this.emit('init');
                            return [4 /*yield*/, promise.wait(1)];
                        case 4:
                            _a.sent();
                            this.emit('show', this.currentPage);
                            return [4 /*yield*/, this.currentPage.askShow(prev, el)];
                        case 5:
                            _a.sent();
                            this.currentPage.transitionComplete();
                            return [2 /*return*/];
                    }
                });
            });
        }
    });
    Object.defineProperty(PageManager.prototype, "initializeRoutes", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var _this = this;
            router.on('**', function (event) {
                var pathName = event ? compact([event.url, event.queryString]).join('?') : '';
                _this.onRouteUpdate('/' + pathName);
            });
            defer(function () { return router.resolve(); });
        }
    });
    Object.defineProperty(PageManager.prototype, "virtual", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (pathName, requestOptions) {
            this.emit('virtual', pathName);
            this.rewriteRoute(pathName, false);
            this.onRouteUpdate(pathName, requestOptions);
        }
    });
    Object.defineProperty(PageManager.prototype, "rewriteRoute", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (pathName, overwrite) {
            if (overwrite === void 0) { overwrite = true; }
            if (~pathName.indexOf(getRoot()))
                pathName = pathName.replace(getRoot(), '');
            if (overwrite) {
                router.navigate(pathName, { callHandler: false, callHooks: false });
            }
            else {
                if (pathName === this.newPathName)
                    return;
                this.disabled = true;
                router.navigate(pathName, { callHandler: false, callHooks: false });
                this.disabled = false;
            }
        }
    });
    Object.defineProperty(PageManager.prototype, "inject", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (pathName, xhr, requestOptions) {
            var _this = this;
            if (requestOptions === void 0) { requestOptions = {}; }
            var cb = function () {
                _this.rewriteRoute(pathName, false);
                _this.pageLoaded(pathName, xhr, requestOptions);
            };
            if (this.state.transitioning || this.state.loading)
                this.once('shown', cb);
            else
                cb();
        }
    });
    Object.defineProperty(PageManager.prototype, "navigateTo", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (pathName) {
            router.navigate(pathName);
        }
    });
    Object.defineProperty(PageManager.prototype, "onRouteUpdate", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (pathName, requestOptions) {
            if (requestOptions === void 0) { requestOptions = {}; }
            if (this.disabled)
                return false;
            if (!this.currentPage && this.extractPage(this.container) && !this.previousPage) {
                this.initializePage();
                return true;
            }
            pathName = pathName.split('#')[0];
            // if (this.state.transitioning || this.state.loading) {
            //   this.rewriteRoute(this.newPathName as string)
            //   this.waitingForPage = pathName
            //   return
            // }
            this.newPathName = pathName;
            this.emit('navigate', pathName);
            if (!this.loadBeforeHide) {
                if (this.currentPage)
                    this.previousPage = this.currentPage;
                if (this.previousPage)
                    this.hidePage();
            }
            this.emit('loading');
            this.load(pathName, requestOptions);
            return true;
        }
    });
    Object.defineProperty(PageManager.prototype, "load", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (pathName, _a) {
            var _this = this;
            if (_a === void 0) { _a = {}; }
            var _b = _a.method, method = _b === void 0 ? 'GET' : _b, body = _a.body, options = __rest(_a, ["method", "body"]);
            this.emit('load:start');
            if (this.xhr) {
                this.xhr.onload = this.xhr.onerror = null;
                this.xhr.abort();
            }
            this.state.loading = true;
            document.documentElement.classList.add('loading');
            this.xhr = new XMLHttpRequest();
            this.xhr.withCredentials = true;
            this.xhr.open(method, pathName, true);
            this.xhr.setRequestHeader('X-Fursac-Ajax', 'true');
            this.xhr.responseType = 'document';
            this.xhr.onload = function (event) {
                _this.pageLoaded(pathName, event.target, options);
                _this.emit('load:end');
                // document.documentElement.classList.remove('loading')
            };
            this.xhr.send(body);
        }
    });
    Object.defineProperty(PageManager.prototype, "triggerInternalRouting", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (pathName, xhr, requestOptions) {
            var _a, _b;
            requestOptions.rewriteRoute = this.rewriteRoute.bind(this);
            this.state.loading = false;
            (_b = (_a = this.currentPage) === null || _a === void 0 ? void 0 : _a.internalRouting) === null || _b === void 0 ? void 0 : _b.call(_a, pathName, xhr, requestOptions);
        }
    });
    Object.defineProperty(PageManager.prototype, "cancelTransition", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            this.rewriteRoute(this.pathName, true);
            this.state.loading = false;
            this.emit('load:end');
            this.emit('loaded');
            document.documentElement.classList.remove('loading');
        }
    });
    Object.defineProperty(PageManager.prototype, "pageLoaded", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (pathName, xhr, requestOptions) {
            return __awaiter(this, void 0, void 0, function () {
                var page, el, routerKey, router_1, PageClass, current, newPath;
                var _a;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            page = xhr.response;
                            el = this.extractPageFromXHR(page.body);
                            this.state.loading = false;
                            document.documentElement.classList.remove('loading');
                            // DETECT ERROR
                            if (!el) {
                                console.error('PageManager: No page found in response'); // eslint-disable-line
                                document.documentElement.innerHTML = page.documentElement.innerHTML; // Unhandled errors
                                return [2 /*return*/, false];
                            }
                            // INJECT IN ANOTHER ROUTER
                            if (el.hasAttribute('data-router')) {
                                routerKey = el.getAttribute('data-router');
                                el.removeAttribute('data-router');
                                router_1 = store.routers.get()[routerKey];
                                if (router_1 && router_1 !== this) {
                                    router_1.inject(pathName, xhr, requestOptions);
                                    this.cancelTransition();
                                    return [2 /*return*/, false];
                                }
                            }
                            return [4 /*yield*/, this.getPageClass(el)];
                        case 1:
                            PageClass = _b.sent();
                            current = this.currentPage;
                            // INTERNAL ROUTING
                            if (current &&
                                current.pageName() === PageClass.pageName &&
                                current.internalRouting &&
                                ((_a = current.shouldRouteInternally) === null || _a === void 0 ? void 0 : _a.call(current, el, pathName))) {
                                this.triggerInternalRouting(pathName, xhr, requestOptions);
                                return [2 /*return*/, false];
                            }
                            this.pathName = xhr.responseURL;
                            this.state.next = PageClass.pageName;
                            if (xhr.responseURL !== pathName && ~xhr.responseURL.indexOf(getRoot())) {
                                newPath = xhr.responseURL.replace(getRoot(), '');
                                if (requestOptions.rewriteRoute)
                                    requestOptions.rewriteRoute(newPath, false, false);
                                else
                                    this.rewriteRoute(newPath, false);
                            }
                            this.emit('loaded', page);
                            this.extractPageInfo(page);
                            this.preloadPage(el, PageClass);
                            xhr.onload = null;
                            return [2 /*return*/, true];
                    }
                });
            });
        }
    });
    Object.defineProperty(PageManager.prototype, "extractPageInfo", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (page) {
            this.nextPageInfo = {
                title: page.title,
                bodyClassName: page.body.className
            };
        }
    });
    Object.defineProperty(PageManager.prototype, "preloadPage", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (el, PageClass) {
            return __awaiter(this, void 0, void 0, function () {
                var page, previousPage;
                var _a;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            if (this.loadBeforeHide && this.currentPage)
                                this.previousPage = this.currentPage;
                            page = this.createPage(el, PageClass);
                            this.currentPage = page;
                            previousPage = this.previousPage && this.previousPage.pageName();
                            this.container.appendChild(page.el);
                            return [4 /*yield*/, promise.wait(1)];
                        case 1:
                            _b.sent();
                            return [4 /*yield*/, page.askPreload(previousPage)];
                        case 2:
                            _b.sent();
                            this.state.loading = false;
                            document.documentElement.classList.remove('loading');
                            this.state.transitioning = true;
                            if (this.previousPage) {
                                if ((_a = this.previousPage) === null || _a === void 0 ? void 0 : _a.state.hidden)
                                    this.pageHidden();
                                else if (this.loadBeforeHide)
                                    this.hidePage();
                                if (this.crossTransition)
                                    this.showPage();
                            }
                            else {
                                this.showPage();
                            }
                            return [2 /*return*/];
                    }
                });
            });
        }
    });
    Object.defineProperty(PageManager.prototype, "showPage", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return __awaiter(this, void 0, void 0, function () {
                var previousPage, bodyClassName, classes, variants, otherClasses;
                var _a, _b, _c, _d, _e, _f, _g;
                return __generator(this, function (_h) {
                    switch (_h.label) {
                        case 0:
                            if (!this.currentPage)
                                return [2 /*return*/];
                            previousPage = this.state.previous;
                            if (this.main) {
                                bodyClassName = ((_a = this.nextPageInfo) === null || _a === void 0 ? void 0 : _a.bodyClassName) || '';
                                classes = bodyClassName.split(' ');
                                variants = classes.filter(function (c) { return c.includes('header__'); }).map(function (c) { return c.replace('header__', ''); });
                                otherClasses = classes.filter(function (c) { return !c.includes('header__'); });
                                document.body.className = otherClasses.join(' ');
                                store.headerVariants.set(variants);
                                if ((_b = this.nextPageInfo) === null || _b === void 0 ? void 0 : _b.notification)
                                    document.body.prepend(((_c = this.nextPageInfo) === null || _c === void 0 ? void 0 : _c.notification) || '');
                                else
                                    (_d = document.body.querySelector('notification')) === null || _d === void 0 ? void 0 : _d.remove();
                                if ((_e = this.nextPageInfo) === null || _e === void 0 ? void 0 : _e.title)
                                    document.title = ((_f = this.nextPageInfo) === null || _f === void 0 ? void 0 : _f.title) || '';
                            }
                            return [4 /*yield*/, promise.wait()];
                        case 1:
                            _h.sent();
                            updatePageLinks();
                            this.emit('show', this.currentPage);
                            return [4 /*yield*/, this.currentPage.askShow(previousPage, (_g = this.previousPage) === null || _g === void 0 ? void 0 : _g.el)];
                        case 2:
                            _h.sent();
                            this.pageShown();
                            return [2 /*return*/];
                    }
                });
            });
        }
    });
    Object.defineProperty(PageManager.prototype, "transitionComplete", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var _a;
            if (!this.state.transitioning)
                return;
            this.state.transitioning = false;
            this.emit('shown', this.currentPage);
            (_a = this.currentPage) === null || _a === void 0 ? void 0 : _a.transitionComplete();
        }
    });
    Object.defineProperty(PageManager.prototype, "hidePage", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return __awaiter(this, void 0, void 0, function () {
                var nextPage;
                var _a;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            if (!this.previousPage)
                                return [2 /*return*/];
                            nextPage = this.state.next;
                            this.state.previous = this.previousPage.pageName();
                            this.emit('hide', this.previousPage);
                            return [4 /*yield*/, this.previousPage.askHide(nextPage, (_a = this.currentPage) === null || _a === void 0 ? void 0 : _a.el)];
                        case 1:
                            _b.sent();
                            this.pageHidden();
                            return [2 /*return*/];
                    }
                });
            });
        }
    });
    Object.defineProperty(PageManager.prototype, "removePage", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            if (!this.previousPage)
                return;
            this.container.removeChild(this.previousPage.el);
            this.previousPage.flush();
            this.previousPage = undefined;
        }
    });
    Object.defineProperty(PageManager.prototype, "getPageClass", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (el) {
            return __awaiter(this, void 0, void 0, function () {
                var pageClassName, firstCharToLowerCase, pageName;
                return __generator(this, function (_a) {
                    if (!el)
                        return [2 /*return*/, this.defaultPageClass];
                    pageClassName = el.getAttribute('data-page') || '';
                    firstCharToLowerCase = function (str) { return str.charAt(0).toLowerCase() + str.slice(1); };
                    pageName = pageClassName && firstCharToLowerCase(pageClassName);
                    return [2 /*return*/, pageMap[pageName] || this.defaultPageClass];
                });
            });
        }
    });
    Object.defineProperty(PageManager.prototype, "extractPageFromXHR", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (el) {
            return this.extractPage(el);
        }
    });
    Object.defineProperty(PageManager.prototype, "extractPage", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (el) {
            return el === null || el === void 0 ? void 0 : el.querySelector(this.pageSelector);
        }
    });
    Object.defineProperty(PageManager.prototype, "createPage", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (el, PageClass) {
            var page = new PageClass(el, this.parameters, this);
            return page;
        }
    });
    Object.defineProperty(PageManager.prototype, "flush", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            if (this.currentPage)
                this.currentPage.flush();
            if (this.previousPage)
                this.previousPage.flush();
        }
    });
    return PageManager;
}(TinyEmitter));
export default PageManager;
