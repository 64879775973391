var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { bindEmitterMethod } from 'helpers/bind';
import Component, { forceArray } from 'navigation/component/Component';
import ReturnsItemRow from './ReturnsItemRow';
var ReturnsItem = /** @class */ (function (_super) {
    __extends(ReturnsItem, _super);
    function ReturnsItem() {
        var _this = _super.apply(this, __spreadArray([], __read(arguments), false)) || this;
        Object.defineProperty(_this, "handleItemChoiceUpdate", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (value) {
                _this.emit('update:choice', value);
            }
        });
        Object.defineProperty(_this, "handleItemReasonUpdate", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (value) {
                _this.emit('update:reason', value);
            }
        });
        Object.defineProperty(_this, "handleUpdateSize", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (value) {
                _this.emit('update:size', value);
            }
        });
        Object.defineProperty(_this, "handleUpdateColor", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (value) {
                _this.emit('update:color', value);
            }
        });
        return _this;
    }
    Object.defineProperty(ReturnsItem.prototype, "initialized", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            _super.prototype.initialized.call(this);
        }
    });
    Object.defineProperty(ReturnsItem.prototype, "getModulesMap", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return {
                rows: ['.returns__item-row', ReturnsItemRow]
            };
        }
    });
    Object.defineProperty(ReturnsItem.prototype, "bindEvents", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (add) {
            var _this = this;
            var emitterMethod = bindEmitterMethod(add);
            if (this.modules.rows) {
                forceArray(this.modules.rows).forEach(function (row) {
                    row[emitterMethod]('update:choice', _this.handleItemChoiceUpdate);
                    row[emitterMethod]('update:reason', _this.handleItemReasonUpdate);
                    row[emitterMethod]('update:size', _this.handleUpdateSize);
                    row[emitterMethod]('update:color', _this.handleUpdateColor);
                });
            }
        }
    });
    return ReturnsItem;
}(Component));
export default ReturnsItem;
