var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import CheckoutPage from 'navigation/pages/CheckoutPage';
import { bindEmitterMethod } from 'helpers/bind';
import Form from 'components/form/Form';
import { forceArray } from 'navigation/component/Component';
import ReturnsItem from './partials/ReturnsItem';
var ReturnsItems = /** @class */ (function (_super) {
    __extends(ReturnsItems, _super);
    function ReturnsItems() {
        var _this = _super.apply(this, __spreadArray([], __read(arguments), false)) || this;
        Object.defineProperty(_this, "handleFormChange", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function () {
                var hasChoice = Object.keys(_this.modules.form.getFormValues()).join('').includes('choice');
                if (!hasChoice) {
                    _this.modules.form.el.classList.add('not-valid');
                    _this.refs.nextStep[0].disabled = true;
                }
                else {
                    _this.refs.nextStep[0].disabled = false;
                    _this.modules.form.quickValid();
                }
            }
        });
        Object.defineProperty(_this, "handleFormSubmitted", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function () {
                console.log(_this.modules.form.getFormValues());
            }
        });
        Object.defineProperty(_this, "handleItemChoiceUpdate", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (value) {
                _this.modules.form.bindEvents(false);
                _this.modules.form.parseForm();
                _this.modules.form.bindEvents(true);
                _this.modules.form.quickValid();
            }
        });
        Object.defineProperty(_this, "handleItemReasonUpdate", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (value) {
                _this.modules.form.bindEvents(false);
                _this.modules.form.parseForm();
                _this.modules.form.bindEvents(true);
                _this.modules.form.quickValid();
            }
        });
        Object.defineProperty(_this, "handleUpdateSize", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (value) {
                _this.modules.form.bindEvents(false);
                _this.modules.form.parseForm();
                _this.modules.form.bindEvents(true);
                _this.modules.form.quickValid();
            }
        });
        return _this;
    }
    Object.defineProperty(ReturnsItems.prototype, "initialized", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            _super.prototype.initialized.call(this);
            this.handleFormChange();
        }
    });
    Object.defineProperty(ReturnsItems.prototype, "bindEvents", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (add) {
            var _this = this;
            if (add === void 0) { add = true; }
            _super.prototype.bindEvents.apply(this, __spreadArray([], __read(arguments), false));
            var emitterMethod = bindEmitterMethod(add);
            forceArray(this.modules.items).forEach(function (item) {
                item[emitterMethod]('update:choice', _this.handleItemChoiceUpdate);
                item[emitterMethod]('update:reason', _this.handleItemReasonUpdate);
            });
            this.modules.form[emitterMethod]('submitted', this.handleFormSubmitted);
            this.modules.form.el.addEventListener('change', this.handleFormChange);
        }
    });
    Object.defineProperty(ReturnsItems.prototype, "getModulesMap", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return {
                form: ['.returns__items-form', Form],
                items: ['.returns__item', ReturnsItem]
            };
        }
    });
    Object.defineProperty(ReturnsItems.prototype, "resize", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            _super.prototype.resize.call(this);
        }
    });
    Object.defineProperty(ReturnsItems.prototype, "flush", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            _super.prototype.flush.call(this);
        }
    });
    return ReturnsItems;
}(CheckoutPage));
export default ReturnsItems;
