var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import DragController from 'components/drag-controller/DragController';
import { bindMethod } from 'helpers/bind';
import Component from 'navigation/component/Component';
import store from 'store';
var Facets = /** @class */ (function (_super) {
    __extends(Facets, _super);
    function Facets() {
        var _this = _super.apply(this, __spreadArray([], __read(arguments), false)) || this;
        Object.defineProperty(_this, "activeItem", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(_this, "mo", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(_this, "detectActive", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function () {
                var activeItem = _this.refs.draggableItems.find(function (item) { return item.getAttribute('href') === window.location.href; });
                if (activeItem) {
                    _this.activeItem = activeItem;
                    _this.refs.draggableItems.forEach(function (item) {
                        item.classList.add('inactive');
                    });
                    _this.activeItem.classList.remove('inactive');
                }
                else {
                    _this.refs.draggableItems.forEach(function (item) {
                        item.classList.remove('inactive');
                        _this.activeItem = null;
                    });
                }
            }
        });
        Object.defineProperty(_this, "onClick", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (e) {
                var target = e.currentTarget;
                if (target.getAttribute('href').includes('?') && window.location.href === target.getAttribute('href')) {
                    _this.refs.draggableItems.forEach(function (item) {
                        item.classList.add('inactive');
                    });
                    target.classList.remove('inactive');
                    _this.activeItem = target;
                }
            }
        });
        Object.defineProperty(_this, "onMouseEnter", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (e) {
                var target = e.currentTarget;
                _this.refs.draggableItems.forEach(function (item) {
                    item.classList.add('inactive');
                });
                target.classList.remove('inactive');
            }
        });
        Object.defineProperty(_this, "onMouseLeave", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (e) {
                _this.refs.draggableItems.forEach(function (item) {
                    if (_this.activeItem) {
                        if (item === _this.activeItem)
                            item.classList.remove('inactive');
                        else
                            item.classList.add('inactive');
                    }
                    else {
                        item.classList.remove('inactive');
                    }
                });
            }
        });
        return _this;
    }
    Object.defineProperty(Facets.prototype, "getModulesMap", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return {
                facets: ['self', DragController]
            };
        }
    });
    Object.defineProperty(Facets.prototype, "getModuleParams", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (el, componentConstructor) {
            return __assign(__assign({}, _super.prototype.getModuleParams.call(this, el, componentConstructor)), { gradient: true });
        }
    });
    Object.defineProperty(Facets.prototype, "initialized", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            _super.prototype.initialized.call(this);
            this.detectActive();
        }
    });
    Object.defineProperty(Facets.prototype, "bindEvents", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (add) {
            var _this = this;
            var method = bindMethod(add);
            this.refs.draggableItems.forEach(function (item) {
                item[method]('mouseenter', function (e) { return _this.onMouseEnter(e); });
                item[method]('mouseleave', function (e) { return _this.onMouseLeave(e); });
            });
            store.fullPath.toggleListener(add, this.detectActive);
        }
    });
    return Facets;
}(Component));
export default Facets;
